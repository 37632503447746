export const _USER = {
    REQUEST: "REQUEST",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOGIN: 'LOGIN',
    GET_ALL_USERS: "GET_ALL_USERS",
    GET_USER_BY_ID: "GET_USER_BY_ID"
};

export const _DOCUMENT = {
    REQUEST_DOC: "REQUEST_DOC",
    SUCCESS_DOC: "SUCCESS_DOC",
    FAILURE_DOC: "FAILURE_DOC",
    GET_ALL_DOCS: 'GET_ALL_DOCS'
};

export const _FOLDER = {
    REQUEST_FOLDER: "REQUEST_FOLDER",
    SUCCESS_FOLDER: "SUCCESS_FOLDER",
    FAILURE_FOLDER: "FAILURE_FOLDER",
    GET_ALL_FOLDER: "GET_ALL_FOLDER",
    GET_FOLDER_BY_USER_ID: "GET_FOLDER_BY_USER_ID",
    GET_FILES_AND_FOLDERS_BY_ID: "GET_FILES_AND_FOLDERS_BY_ID"
}

export const _UTIL = {
    REQUEST_UTIL: "REQUEST_UTIL",
    FAILURE_UTIL: "FAILURE_UTIL",
    SUCCESS_UTIL: "SUCCESS_UTIL",
    GET_SEARCH_FILES_AND_FOLDERS: "GET_SEARCH_FILES_AND_FOLDERS",
    GET_PENDINGS_BY_USER_ID: "GET_PENDINGS_BY_USER_ID",
    GET_SHARED_FILES_BY_USER_ID: "GET_SHARED_FILES_BY_USER_ID",
    GET_ALL_RECORDS: "GET_ALL_RECORDS"
}

export const _PROFILE = {
    REQUEST_PRO: "REQUEST_PRO",
    SUCCESS_PRO: "SUCCESS_PRO",
    FAILURE_PRO: "FAILURE_PRO",
    GET_ALL_PRO: "GET_ALL_PRO"
}

export const _PERMISSION = {
    REQUEST_PERM: "REQUEST_PERM",
    SUCCESS_PERM: "SUCCESS_PERM",
    FAILURE_PERM: "FAILURE_PERM",
    GET_ALL_MODULES: "GET_ALL_MODULES",
    GET_ALL_PERM_BY_USER_ID: "GET_ALL_PERM_BY_USER_ID"
}

export const _DATASHEET = {
    REQUEST_DAT: "REQUEST_DAT",
    SUCCESS_DAT: "SUCCESS_DAT",
    FAILURE_DAT: "FAILURE_DAT",
    GET_ALL_DAT: "GET_ALL_DAT",
    GET_FORM_PROPERTIES: "GET_FORM_PROPERTIES",
    GET_DATASHET: "GET_DATASHET"
};

export const _REMINDER = {
    REQUEST_RMN: "REQUEST_RMN",
    SUCCESS_RMN: "SUCCESS_RMN",
    FAILURE_RMN: "FAILURE_RMN",
    GET_ALL_RMN: "GET_ALL_RMN",
    GET_ALL_RMN_USER: "GET_ALL_RMN_USER",
    GET_ALL_REMINDER_TIMES: "GET_ALL_REMINDER_TIMES"
};
