import React, { Component } from 'react';
import { DatePicker } from "antd";
import moment from "moment";
import "moment/locale/es";
import { connect } from 'react-redux';

import { getAllRecords } from '../actions/util.actions';
import { Table } from "../components";
import { tb_record } from "../assets/file/table.json";

class MRecord extends Component {

    constructor(props) {
        super(props);
        this.state = {
            start_date: moment().startOf('month'),
            end_date: moment().endOf("month")
        }
    }

    componentDidMount() {
        this.handleGetAll();
    }

    handleOnChange = ({ name, value }) => {
        this.setState({ [name]: value }, () => {
            if (this.state.start_date !== null &&
                this.state.end_date !== null) {
                this.handleGetAll();
            }
        });
    }

    handleGetAll = () => {
        const { start_date, end_date } = this.state;
        this.props.dispatch(getAllRecords({
            start_date: start_date.format("YYYY-MM-DD"),
            end_date: end_date.format("YYYY-MM-DD"),
        }));
    }

    render() {
        const { start_date, end_date } = this.state;
        const { records, permission_user } = this.props;
        return (
            <div className="container-view">
                <p className="h3 p-0 m-0 text-title" >
                    Consulta de registros
                </p>
                <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-6">
                                <label className="m-0 p-0">De</label>
                                <DatePicker
                                    value={start_date}
                                    format="DD/MM/YYYY"
                                    onChange={(value) => this.handleOnChange({ name: "start_date", value })}
                                    name="start_date"
                                    disabled={(permission_user && permission_user['6'] && permission_user['6']['11']) ? false : true}
                                />
                            </div>
                            <div className="col-6">
                                <label className="m-0 p-0">A</label>
                                <DatePicker
                                    value={end_date}
                                    format="DD/MM/YYYY"
                                    onChange={(value) => this.handleOnChange({ name: "end_date", value })}
                                    disabled={(permission_user && permission_user['6'] && permission_user['6']['11']) ? false : true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    data={records}
                    arr={tb_record}
                    height="65vh"
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { _util, _permission } = state;
    const { records } = _util;
    const { permission_user } = _permission;
    return { records, permission_user };
}

export default connect(mapStateToProps)(MRecord);
