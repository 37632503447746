import { _DOCUMENT } from "../reducers/constants";
import { _GET, _POST } from "../services";

const _PATH = "document.php?";
function request() { return { type: _DOCUMENT.REQUEST_DOC }; }
function failure(err) { return { type: _DOCUMENT.FAILURE_DOC, err }; }
function success(types, message) { return { type: _DOCUMENT.SUCCESS_DOC, types, message }; }


export function getAllDocuments() {
    return dispatch => {
        dispatch(request());
        _GET(_PATH + "get_all_doc=true").then(res => {
            dispatch({ type: _DOCUMENT.GET_ALL_DOCS, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function addOrEditDocument(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "add_or_edit_doc=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err) dispatch(getAllDocuments());
        }).catch(err => dispatch(failure(err)));
    }
}
