import React from 'react';
import { PropagateLoader, DotLoader } from 'react-spinners';

export const Loading = ({ type = false }) => (
    type ?
        <PropagateLoader
            sizeUnit={"px"}
            size={15}
            color={'#F4D03F'}
        />
        :
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: 40 }}>
                <p style={{ color: 'gray', fontWeight: 'bold' }}>CARGANDO...</p>
            </div>
            <DotLoader
                sizeUnit={"px"}
                size={100}
                color={'#f57f17'}
            />
        </div>
);