import React, { Component } from 'react';
import { connect } from "react-redux";
import { Divider } from "antd";
import { UserOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import Rodal from "rodal";

import { login, setRestorePass } from '../actions/user.actions';
import logo from "../assets/img/logo.png";
import logo_oca from "../assets/img/logo_oca.png";
import logo_software from "../assets/img/logo-software.png";
import { frm_restore_pass } from "../assets/file/form.json";
import { Forms, Loading, View, Modal } from "../components";
import { getScreenMode } from "../helpers";
import { _SERVER } from '../services';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal_restore_pass: false,
            screen_mode: false,
            user: {
                username: "",
                pass: ""
            },
            show_pass: false,
            item: null
        }
    }

    componentDidMount() {
        this.setState({ screen_mode: getScreenMode() });
        window.addEventListener('resize', () => this.setState({ screen_mode: getScreenMode() }));
    }

    render() {
        const { modal_restore_pass, show_pass, user, screen_mode, item } = this.state;
        return (
            <div className="login-container">
                <Modal
                    item={item}
                    onClose={() => this.setState({ item: null })}
                    screen_mode={screen_mode}
                />
                {modal_restore_pass && this.handleModalRestorePass()}
                <div className="row h-100 justify-content-center align-items-center">
                    <div className="login-card">
                        <div className="card" style={{ borderRadius: 25 }}>
                            <div className="card-body p-5">
                                <center className="mb-3">
                                    <img alt="logo" width="50%" src={logo} />
                                </center>
                                <form
                                    className="from from-horizontal"
                                    onSubmit={(env) => {
                                        env.preventDefault();
                                        const { username, pass } = user;
                                        if (username.trim() !== "" && pass.trim() !== "") {
                                            this.props.dispatch(login(user));
                                        }
                                    }}
                                >
                                    <div className="input-group mb-3 rounded-circle">
                                        <div className="input-group-prepend">
                                            <span
                                                className="input-group-text input-login"
                                                id="basic-addon1"
                                            >
                                                <UserOutlined />
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control input-login"
                                            placeholder="Correo/Usuario"
                                            aria-label="username"
                                            name="username"
                                            aria-describedby="basic-addon1"
                                            value={user.username}
                                            onChange={(env) => this.handleOnChange(env)}
                                        />
                                    </div>
                                    <div className="input-group mb-4 rounded-circle">
                                        <div className="input-group-prepend">
                                            <span
                                                className="input-group-text input-login"
                                                id="basic-addon1"
                                                onClick={() => this.setState({ show_pass: !show_pass })}
                                            >
                                                {show_pass ?
                                                    <EyeInvisibleOutlined /> :
                                                    <EyeOutlined />
                                                }
                                            </span>
                                        </div>
                                        <input
                                            type={show_pass ? "text" : "password"}
                                            className="form-control input-login"
                                            placeholder="Contraseña"
                                            aria-label="pass"
                                            name="pass"
                                            aria-describedby="basic-addon1"
                                            autoComplete="on"
                                            value={user.pass}
                                            onChange={(env) => this.handleOnChange(env)}
                                        />
                                    </div>
                                    <p
                                        className="text-center login-text"
                                        onClick={() => this.setState({ modal_restore_pass: true })}
                                    >
                                        ¿Olvidaste la contraseña?
                                    </p>
                                    <p
                                        className="text-center login-text"
                                        onClick={() => this.setState({
                                            item: {
                                                modal: "view_file",
                                                path: "Manual de usuarios.pdf",
                                                type: "pdf",
                                                url: _SERVER._PUBLIC + "file/Manual de usuarios.pdf",
                                                download: true
                                            }
                                        })}
                                    >
                                        ¿Necesitas ayuda?
                                    </p>
                                    <center>
                                        <button
                                            className="btn btn-login mt-3"
                                            type="submit"
                                        >
                                            Iniciar sesión
                                        </button>
                                    </center>
                                    <div className="mt-3 d-flex justify-content-center">
                                        <img
                                            alt="logo"
                                            width="30%"
                                            src={logo_oca}
                                            className="mr-3"
                                        />
                                        <img alt="logo" width="20%" src={logo_software} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <View.Footer
                    color="white"
                    title={true}
                />
            </div>
        );
    }

    handleOnChange = (env) => {
        let { user } = this.state;
        const { name, value } = env.target;
        user[name] = value;
        this.setState({ user });
    }

    handleModalRestorePass() {
        const { modal_restore_pass, screen_mode } = this.state;
        const { loading } = this.props;
        return (
            <Rodal
                animation={"slideRight"}
                duration={500}
                visible={modal_restore_pass}
                onClose={() => this.setState({ modal_restore_pass: false })}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={230}
            >
                <h6>Recuperar contraseña</h6>
                <Divider />
                {loading &&
                    <center>
                        <Loading type={true} />
                    </center>
                }
                <Forms
                    name="frm-restore-pass"
                    disabled={loading}
                    objects={frm_restore_pass}
                    btn_title="Enviar"
                    handleSubmit={(values) => this.props.dispatch(setRestorePass(values))}
                />
            </Rodal>
        )
    }
}

function mapStateToProps(state) {
    const { _user } = state;
    const { loading } = _user;
    return { loading };
}

export default connect(mapStateToProps)(Login);
