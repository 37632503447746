let _HOST;
if (process.env.REACT_APP_HOST) {
    _HOST = process.env.REACT_APP_HOST;
} else {
    const _URL_TEMP = (window.location.href);
    _HOST = _URL_TEMP.split("/#")[0];
}
const _VERSION = process.env.REACT_APP_API_VERSION;

export const _SERVER = {
    _URL: `${_HOST}/api/${_VERSION}/`,
    _PUBLIC: `${_HOST}/api/public/`
}

export const _KEYS = {
    _SESSION: "a629a2f5-3354-11ec-82f6-0242ac120003",
    _TOKEN: "ab41f2d9-3354-11ec-82f6-0242ac120003"
}

function authHeader() {
    const token = window.sessionStorage.getItem(_KEYS._TOKEN);
    if (token && token !== "") {
        return {
            'Accept': 'application/json',
            'API-KEY': token
        }
    }
    return { 'Accept': 'application/json' };
}

export function _GET(path) {
    return fetch(_SERVER._URL + path, {
        method: "GET",
        headers: authHeader()
    }).then(handleResponse).catch(err => console.error(err));
}

export function _POST(path, data = {}) {
    let body = new FormData();
    Object.keys(data).forEach(key => body.append(key, data[key]));
    return fetch(_SERVER._URL + path, {
        method: "POST",
        headers: authHeader(),
        body
    }).then(handleResponse).catch(err => console.error(err));
}

function handleResponse(response) {
    return response.text().then(text => {
        let data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}