import React, { Component } from 'react';
import { Provider } from "react-redux";

import { store } from "./reducers";
import { Navigation } from './pages/';

export default class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Navigation />
            </Provider>
        )
    }
}
