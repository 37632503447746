import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import moment from "moment";
import "moment/min/locales.min";

import { getFilesAndFoldersById } from '../actions/folder.actions';
import { addOrEditReminder, getRemindersByUser } from '../actions/reminder.actions';
import { getPendingsByUserId } from '../actions/util.actions';
import { TableSemantic } from '../components';
import { _SERVER } from '../services';

moment.locale("es");

class Pending extends Component {

    componentDidMount() {
        const { session } = this.props;
        this.props.dispatch(getPendingsByUserId(session));
        this.props.dispatch(getRemindersByUser(session));
    }

    render() {
        const { pending_by_user, reminders_user, session, reminders_automatic, new_or_edited_files } = this.props;
        return (
            <div className="container-view pendings">
                <div className="row">
                    {pending_by_user &&
                        <TableSemantic
                            title="Pendientes"
                            headers={[
                                { title: "No.", width: 1, id: "index" },
                                { title: "Expediente", width: 6, id: "folder", tooltip: true, mensaje: 'folder' },
                                { title: "Archivos faltantes", width: 3, id: "document", error: true },
                            ]}
                            arr_body={pending_by_user}
                            onClickRow={(item) => {
                                this.props.dispatch(getFilesAndFoldersById({
                                    id_folder: item.id_folder,
                                    id_user: session.id_user
                                }));
                                this.props.handleNavClick(true);
                            }}
                            dropdown_menu={false}
                        />
                    }
                    {reminders_user &&
                        <TableSemantic
                            title="Recordatorios"
                            color="blue"
                            headers={[
                                { title: "No.", width: 1, id: "index" },
                                { title: "Recordatorio", width: 5, id: "title", tooltip: true, warning: true, mensaje: 'message' },
                                { title: "Fecha", width: 3, id: "reminder_date" },
                                { title: "Opc.", width: 1, id: "menu" },
                            ]}
                            arr_body={reminders_user}
                            onClickRow={() => { }}
                            dropdown_menu={true}
                            onClickEdit={(item) => {
                                let _item = { ...item };
                                _item.reminder_date = moment(_item.reminder_date, "DD-MM-YYYY");
                                _item.modal = "reminder";
                                this.props.handleOnClick(_item);
                            }}
                            onClickDelete={(item) => {
                                Modal.confirm({
                                    title: `Eliminar recordatorio`,
                                    content: `¿Estás seguro de que quieres eliminar este recordatorio?`,
                                    okText: "SI",
                                    cancelText: "CANCELAR",
                                    onOk: () => this.props.dispatch(addOrEditReminder({
                                        ...item,
                                        state: 0,
                                        id_user: session.id_user
                                    }))
                                });
                            }}
                            onClickProceedings={(item) => {
                                console.log("onClickProceedings", item);
                                if (item.id_share_file) {
                                    this.props.handleNavClick(false);
                                } else {
                                    this.props.dispatch(getFilesAndFoldersById({
                                        id_folder: item.id_folder,
                                        id_user: session.id_user
                                    }));
                                    this.props.handleNavClick(true);
                                }
                            }}
                        />
                    }
                    {new_or_edited_files &&
                        <TableSemantic
                            title="Nuevos/Actualizados"
                            color="green"
                            headers={[
                                { title: "No.", width: 1, id: "index" },
                                { title: "Archivo", width: 9, id: "name", error: true },
                            ]}
                            arr_body={new_or_edited_files}
                            onClickRow={(item) => {
                                this.props.dispatch(getFilesAndFoldersById({
                                    id_folder: item.id_folder,
                                    id_user: session.id_user
                                }));
                                this.props.handleNavClick(true);
                                this.props.handleOnClick({
                                    ...item,
                                    modal: "view_file",
                                    url: `${_SERVER._PUBLIC}Carpetas/${item.path}`,
                                    download: false
                                });                            
                            }}
                            dropdown_menu={false}
                        />
                    }
                    {reminders_automatic &&
                        <TableSemantic
                            title="Eliminados"
                            color="red"
                            headers={[
                                { title: "No.", width: 1, id: "index" },
                                { title: "Archivo/Carpeta", width: 9, id: "name", error: true },
                            ]}
                            arr_body={reminders_automatic}
                            onClickRow={() => { }}
                            dropdown_menu={false}
                        />
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { _user, _util, _reminder } = state;
    const { session } = _user;
    const { pending_by_user, new_or_edited_files } = _util;
    const { reminders_user, reminders_automatic } = _reminder;
    return {
        session,
        pending_by_user, new_or_edited_files,
        reminders_user, reminders_automatic
    }
}

export default connect(mapStateToProps)(Pending)
