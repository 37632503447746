import React, { Component } from 'react';
import { connect } from "react-redux";
import { PlusOutlined, CameraOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Switch, Tooltip, Divider, Upload, Avatar } from 'antd';
import Rodal from "rodal";
import moment from "moment";
import "moment/min/locales.min";

import { Table, Forms, ModalPermissions } from "../components";
import { tb_user } from "../assets/file/table.json";
import { frm_user } from "../assets/file/form.json";
import { addOrEditUser, getAllUsers } from '../actions/user.actions';
import { getAllPermissionsByUserId } from '../actions/permission.actions';
import { _SERVER } from '../services';
import { getBase64 } from '../helpers';
import { getAllProfiles } from '../actions/profile.actions';

moment.locale('es');

class MUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contents_perm: undefined,
            only_active: true,
            modal: false,
            modal_permissions: false,
            content: null,
            item: null
        }
    }

    componentDidMount() {
        const { session } = this.props;
        this.props.dispatch(getAllUsers());
        this.props.dispatch(getAllPermissionsByUserId(session));
        this.props.dispatch(getAllProfiles());
    }

    render() {
        const { only_active, modal, modal_permissions, item } = this.state;
        const { users, permission_user, session, _users, screen_mode } = this.props;
        return (
            <div className="container-view">
                {modal && this.handleModalUser()}
                {modal_permissions &&
                    <ModalPermissions
                        onClose={() => this.setState({ modal_permissions: false })}
                        item={item}
                        screen_mode={screen_mode}
                    />
                }
                <p className="h3 p-0 m-0 text-title" >
                    Usuarios
                </p>
                <div className="row">
                    <div className="col-3 d-flex align-items-center">
                        <Tooltip title="Permite visualizar Activos/Todos">
                            <Switch
                                size="small"
                                defaultChecked={only_active}
                                checkedChildren="Activos"
                                unCheckedChildren="Todos"
                                onChange={(only_active) => this.setState({ only_active })}
                            />
                        </Tooltip>
                    </div>
                    <div className="col-3 offset-6 col-md-1 offset-md-8 text-right">
                        {(permission_user && permission_user['3'] && permission_user['3']['5']) &&
                            <Tooltip title="Permite agregar un nuevo usuario al sistema...">
                                <div
                                    className="rounded-circle p-3 d-flex align-items-center justify-content-center btn-add"
                                    onClick={() => this.setState({
                                        modal: true,
                                        content: {
                                            state: "1",
                                            restore: "1",
                                            _imagen: null
                                        }
                                    })}
                                >
                                    <PlusOutlined style={{ color: "white", fontSize: 30 }} />
                                </div>
                            </Tooltip>
                        }
                    </div>
                </div>
                <Table
                    data={only_active ? _users : users}
                    arr={tb_user}
                    height="70vh"
                    disabled={(permission_user && permission_user['3'] && permission_user['3']['5']) ? true : false}
                    handleEdit={(item) => this.setState({ content: item, modal: true })}
                    handleChangeStatus={(data) => this.props.dispatch(addOrEditUser({
                        ...data,
                        state: data.state === "1" ? 0 : 1,
                        _id_user: session.id_user
                    }))}
                    handlePermission={(item) => this.setState({
                        item: { ...item, is_profile: false, profile: 'false' },
                        modal_permissions: true
                    })}
                />
            </div>
        )
    }

    handleModalUser = () => {
        const { screen_mode, _profiles, session } = this.props;
        const { content, modal } = this.state;
        const propsUpload = {
            accept: ".png,.jpg,.jpeg",
            multiple: false,
            onRemove: () => this.handleOnChange("_image", null),
            onChange: (info) => info.file.status = "done",
            customRequest: async ({ _, __, file }) => {
                const _image = await getBase64(file);
                let _content = content;
                _content["_image_tem"] = _image;
                _content["_image"] = file;
                this.setState({ content: _content });
            }
        };
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={modal}
                onClose={() => this.setState({ modal: false })}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={screen_mode ? (window.innerHeight < 525 ? window.innerHeight - 50 : 525) : 525}
            >
                {content &&
                    <>
                        <h6>{content.id_user ? `Editar usuario: ${content.full_name}` : "Nuevo usuario"} </h6>
                        <div style={{ height: "90%", overflowX: "hidden", overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-6 text-center">
                                    <p className="m-0 text-left">Estado:</p>
                                    <Switch
                                        size="small"
                                        defaultChecked={content.state === "1" ? true : false}
                                        checkedChildren="Activo"
                                        unCheckedChildren="Inactivo"
                                        onChange={(state) => this.handleOnChange("state", state ? "1" : "0")}
                                    />
                                </div>
                                <div className="col-6 text-center">
                                    <Tooltip
                                        title={`Al restablecer la contraseña el sistema establecera la contraseña: admin-doc-${moment().format("YYYY")}`}
                                    >
                                        <div className="d-flex align-items-center text-left">
                                            <QuestionCircleOutlined style={{ fontSize: 14 }} />&nbsp;
                                            <p className="m-0"> Rest. Contraseña:</p>
                                        </div>
                                        <Switch
                                            size="small"
                                            defaultChecked={content.restore === "1" ? true : false}
                                            checkedChildren="Si"
                                            unCheckedChildren="No"
                                            onChange={(restore) => this.handleOnChange("restore", restore ? "1" : "0")}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <Divider />
                            <div className="row">
                                <div className="col-6 offset-3 text-center">
                                    <Upload {...propsUpload}>
                                        <div style={{ position: 'relative' }}>
                                            <Avatar
                                                src={content._image_tem ? content._image_tem : (content.image ? `${_SERVER._PUBLIC}img/${content.image}` : "")}
                                                size={80}
                                            />
                                            <div className="upload-image">
                                                <CameraOutlined style={{ color: "white", fontSize: 25 }} />
                                            </div>
                                        </div>
                                    </Upload>
                                </div>
                            </div>
                            <br />
                            {_profiles && 
                                <Forms
                                    name="frm-doc"
                                    objects={frm_user}
                                    initial={content}
                                    btn_title="Guardar"
                                    handleSubmit={(values) => {
                                        this.props.dispatch(addOrEditUser({
                                            ...content,
                                            ...values,
                                            _image_tem: null,
                                            _id_user: session.id_user
                                        }));
                                        this.setState({
                                            modal: false,
                                            content: null
                                        });
                                    }}
                                    options={{ _profiles: [..._profiles, { name: '-- SIN PERFIL --', id_profile: 0 }] }}
                                />
                            }
                        </div>
                    </>
                }
            </Rodal>
        )
    }

    handleOnChange = (name, value) => {
        let { content } = this.state;
        content[name] = value;
        this.setState({ content });
    }
}

function mapStateToProps(state) {
    const { _user, _permission, _profile } = state;
    const { users, _users, session } = _user;
    const { permission_user } = _permission;
    const { _profiles } = _profile;
    return {
        users, _users, session,
        permission_user,
        _profiles
    };
}

export default connect(mapStateToProps)(MUser);
