import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Divider, Select, Input, Switch, Tooltip, Tree, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Add, Delete } from "@material-ui/icons";
import Rodal from "rodal";

import { getAllDocuments } from '../actions/document.actions';
import { addOrEditDatasheet, getAllDatasheet, getAllFormProperties } from "../actions/datasheet.actions";
import { getAllPermissionsByUserId } from "../actions/permission.actions";
import { Forms, Table } from "../components";
import { getScreenMode } from '../helpers';
import { tb_catalogue } from "../assets/file/table.json";

const { Option } = Select;
const { TreeNode } = Tree;

class MDatasheet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            id_datasheet: undefined,
            name: "",
            form: [],
            file_required: false,
            required_file: [],
            input: undefined,
            modal_config: false,
            screen_mode: false,
            only_active: true
        }
    }

    componentDidMount() {
        const { session } = this.props;
        this.props.dispatch(getAllDatasheet());
        this.props.dispatch(getAllFormProperties());
        this.props.dispatch(getAllDocuments());
        this.props.dispatch(getAllPermissionsByUserId(session));
        this.setState({ screen_mode: getScreenMode() });
        window.addEventListener('resize', () => this.setState({ screen_mode: getScreenMode() }));
    }

    render() {
        const { datasheets, permission_user, session, _datasheets } = this.props;
        const { modal, modal_config, only_active } = this.state;
        return (
            <div className="container-view">
                {modal && this.handleModal()}
                {modal_config && this.handleModalConfig()}
                <p className="h3 p-0 m-0 text-title" >
                    Catalogo ficha técnica
                </p>
                <div className="row">
                    <div className="col-3 d-flex align-items-center">
                        <Tooltip title="Permite visualizar Activos/Todos">
                            <Switch
                                size="small"
                                defaultChecked={only_active}
                                checkedChildren="Activos"
                                unCheckedChildren="Todos"
                                onChange={(only_active) => this.setState({ only_active })}
                            />
                        </Tooltip>
                    </div>
                    <div className="col-md-1 offset-md-8 col-3 offset-6 text-right">
                        {(permission_user && permission_user['5'] && permission_user['5']['9']) &&
                            <Tooltip title="Permite agregar una nueva ficha al catálogo...">
                                <div
                                    className="rounded-circle p-3 d-flex align-items-center justify-content-center btn-add"
                                    onClick={() => this.setState({
                                        modal: true,
                                        id_datasheet: undefined,
                                        name: "",
                                        form: [],
                                        file_required: false,
                                        required_file: []
                                    })}
                                >
                                    <PlusOutlined style={{ color: "white", fontSize: 30 }} />
                                </div>
                            </Tooltip>
                        }
                    </div>
                </div>
                <Table
                    data={only_active ? _datasheets : datasheets}
                    arr={tb_catalogue}
                    height="70vh"
                    disabled={(permission_user && permission_user['5'] && permission_user['5']['9']) ? true : false}
                    handleEdit={(data) => this.setState({
                        modal: true,
                        id_datasheet: data.id_datasheet,
                        form: data.form,
                        name: data.name,
                        required_file: data.required_file,
                        file_required: data.file_required === "1" ? true : false
                    })}
                    handleChangeStatus={(data) => this.props.dispatch(addOrEditDatasheet({
                        ...data,
                        form: JSON.stringify(data.form),
                        state: data.state === "1" ? 0 : 1,
                        id_user: session.id_user
                    }))}
                />
            </div>
        )
    }

    handleModal() {
        const { modal, screen_mode, form, name, id_datasheet, file_required, required_file } = this.state;
        const { _documents, session } = this.props;
        return (
            <Rodal
                animation={id_datasheet ? "door" : "slideRight"}
                duration={500}
                visible={modal}
                onClose={() => this.setState({ modal: false })}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? window.innerWidth - 50 : 410}
                height={screen_mode ? window.innerHeight - 50 : 500}
            >
                <div style={{ height: "100%", overflowX: "hidden", overflowY: "auto", padding: "0 5px" }}>
                    <h6>{id_datasheet ? "Editar" : "Nuevo"} formulario para ficha</h6>
                    <Divider />
                    <div className="row">
                        <div className="col-12">
                            <Input
                                placeholder="Nombre"
                                value={name}
                                onChange={(evt) => {
                                    const { value } = evt.target;
                                    this.setState({ name: value });
                                }}
                            />
                        </div>
                    </div>
                    <center>
                        <Tooltip title="Permite eliminar el contenido del formulario">
                            <Button
                                className="m-2"
                                type="danger"
                                style={{ borderRadius: 10 }}
                                icon={<Delete />}
                                onClick={() => {
                                    Modal.confirm({
                                        title: "Eliminar formulario",
                                        content: "¿Estás seguro de que quieres eliminar la coiguración del formulario?",
                                        okText: "SI",
                                        cancelText: "CANCELAR",
                                        onOk: () => this.setState({ form: [] })
                                    });
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Permite agregarle propiedades al formulario">
                            <Button
                                className="m-2"
                                type="primary"
                                ghost
                                style={{ borderRadius: 10 }}
                                icon={<Add />}
                                onClick={() => this.setState({ modal_config: true, input: undefined })}
                            />
                        </Tooltip>
                        <Button
                            className="m-2"
                            style={{ borderRadius: 10 }}
                            type="primary"
                            disabled={form.length <= 0 || name === ""}
                            onClick={() => {
                                this.props.dispatch(addOrEditDatasheet({
                                    id_datasheet,
                                    name,
                                    form: JSON.stringify(form),
                                    required_file: required_file.toString(),
                                    file_required: file_required ? 1 : 0,
                                    state: 1,
                                    id_user: session.id_user
                                }));
                                this.setState({
                                    modal: false,
                                    form: [],
                                    name: "",
                                    id_datasheet: undefined,
                                    required_file: [],
                                    file_required: false
                                })
                            }}
                        >
                            Guardar ficha
                        </Button>
                    </center>
                    {(form.length > 0 && _documents && (file_required || required_file.length > 0)) &&
                        <center>
                            <p className="text-left m-0" style={{ fontSize: "0.8rem" }}>
                                Selecciones los archivos predefinidos para esta ficha...
                            </p>
                            <Tree
                                checkable
                                multiple
                                defaultExpandedKeys={required_file}
                                defaultCheckedKeys={required_file}
                                onCheck={(required_file) => this.setState({ required_file })}
                            >
                                {_documents.map((item) => (
                                    <TreeNode
                                        key={item.id_document}
                                        title={item.name}
                                    />
                                ))}
                            </Tree>
                        </center>
                    }
                    <Divider style={{ fontSize: "0.8rem", color: "lightgray" }}> Formulario </Divider>
                    <Forms
                        name="frm-doc"
                        objects={form}
                        btn_title="Guardar"
                        hiden_button={true}
                        options={{ _documents }}
                    />
                </div>
            </Rodal>
        )
    }

    handleModalConfig() {
        const { modal_config } = this.state;
        let { input, form } = this.state;
        const { form_properties } = this.props;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={modal_config}
                onClose={() => this.setState({ modal_config: false })}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={300}
                height={300}
            >
                <h6>Configuracion de propiedad</h6>
                <Divider />
                <Select
                    className="input-text"
                    showSearch
                    autoClearSearchValue
                    placeholder="Selecciones una opcion"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(_, evt) => {
                        const { value } = evt;
                        let _file_required = false;
                        const arr = form_properties.filter(item => {
                            return value === item.id_form_property;
                        });
                        if (arr.length > 0) {
                            if (value === "3") {
                                _file_required = true;
                            }
                            this.setState({ input: arr[0]['settings'], file_required: _file_required });
                        }
                    }}
                >
                    {form_properties && form_properties.map((item, i) => (
                        <Option key={i} value={item.id_form_property}>{item.name}</Option>
                    ))}
                </Select>
                {input &&
                    <div className="m-2">
                        <p className="m-0" style={{ fontSize: "0.8rem" }}>
                            Ingresa un nombre para la entrada de texto:
                        </p>
                        <Input
                            placeholder="Ingresa un nombre"
                            value={input.label}
                            onChange={(evt) => {
                                const { value } = evt.target;
                                input.label = value;
                                if (input.type === 1 || input.type === 3) {
                                    input.name = value;
                                }
                                this.setState({ input });
                            }}
                        />

                        <div className="d-flex justify-content-between align-items-center mt-2">
                            <span style={{ fontSize: "0.8rem" }}>
                                Campo requerido:
                            </span>
                            <Switch
                                defaultChecked={input.required}
                                checkedChildren="Si"
                                unCheckedChildren="No"
                                onChange={(required) => {
                                    input.required = required;
                                    this.setState({ input })
                                }
                                }
                            />
                        </div>
                        <Button
                            className="mt-2"
                            type="primary"
                            block
                            onClick={() => {
                                form.push(input);
                                this.setState({
                                    modal_config: false,
                                    input: undefined,
                                    form
                                });
                            }}
                        >
                            Agregar
                        </Button>
                    </div>
                }
            </Rodal>
        )
    }
}

function mapStateToProps(state) {
    const { _document, _datasheet, _user, _permission } = state;
    const { _documents } = _document;
    const { datasheets, _datasheets, form_properties } = _datasheet;
    const { session } = _user;
    const { permission_user } = _permission;
    return {
        _documents,
        datasheets, _datasheets, form_properties,
        session,
        permission_user
    };
}

export default connect(mapStateToProps)(MDatasheet);