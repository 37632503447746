import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

/** CSS */
import 'rodal/lib/rodal.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'antd/dist/antd.css';
import 'semantic-ui-css/semantic.min.css';
import "bootstrap/dist/css/bootstrap.css";
import './index.css';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
