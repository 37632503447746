import { _UTIL } from "../reducers/constants";
import { _POST } from "../services";
import { getFilesAndFoldersById } from "./folder.actions";

const _PATH = "util.php?";
function request() { return { type: _UTIL.REQUEST_UTIL }; }
function failure(err) { return { type: _UTIL.FAILURE_UTIL, err }; }
function success(types, message) { return { type: _UTIL.SUCCESS_UTIL, types, message }; }

export function shareFile(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "share=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err) dispatch(getFilesAndFoldersById(data));
        }).catch(err => dispatch(failure(err)));
    }
}

export function getPendingsByUserId(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "get_pendings_by_user_id=true", data).then(res => {
            dispatch({ type: _UTIL.GET_PENDINGS_BY_USER_ID, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function getShareFilesByUserId(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "get_shared_file_by_user_id=true", data).then(res => {
            dispatch({ type: _UTIL.GET_SHARED_FILES_BY_USER_ID, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function getSearchFilesAndFolders(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "search=true", data).then(res => {
            dispatch({ type: _UTIL.GET_SEARCH_FILES_AND_FOLDERS, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function getAllRecords(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "get_all_records=true", data).then(res => {
            dispatch({ type: _UTIL.GET_ALL_RECORDS, res });
        }).catch(err => dispatch(failure(err)));
    }
}
