import { _PROFILE } from "../reducers/constants";
import { _GET, _POST } from "../services";

const _PATH = "profile.php?";
function request() { return { type: _PROFILE.REQUEST_PRO }; }
function failure(err) { return { type: _PROFILE.FAILURE_PRO, err }; }
function success(types, message) { return { type: _PROFILE.SUCCESS_PRO, types, message }; }

export function getAllProfiles() {
    return dispatch => {
        dispatch(request());
        _GET(_PATH + "get_all=true").then(res => {
            dispatch({ type: _PROFILE.GET_ALL_PRO, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function addOrEditProfile(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "add_or_edit=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err) dispatch(getAllProfiles());
        }).catch(err => dispatch(failure(err)));
    }
}