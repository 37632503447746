import React, { Component } from 'react';
import { connect } from "react-redux";
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Tooltip, Switch } from 'antd';
import Rodal from "rodal";

import { addOrEditProfile, getAllProfiles } from "../actions/profile.actions";
import { getAllPermissionsByUserId } from '../actions/permission.actions';
import { Forms, ModalPermissions, Table } from "../components";
import { frm_doc } from "../assets/file/form.json";
import { tb_profile } from "../assets/file/table.json";

class MProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            only_active: true,
            modal_permissions: false,
            item: null
        }
    }

    componentDidMount() {
        const { session } = this.props;
        this.props.dispatch(getAllProfiles());
        this.props.dispatch(getAllPermissionsByUserId(session));
    }

    render() {
        const { profiles, permission_user, session, _profiles, screen_mode } = this.props;
        const { modal, only_active, modal_permissions, item } = this.state;
        return (
            <div className="container-view">
                {modal && this.handleModal()}
                {modal_permissions &&
                    <ModalPermissions
                        onClose={() => this.setState({ modal_permissions: false })}
                        item={item}
                        screen_mode={screen_mode}
                    />
                }
                <p className="h3 p-0 m-0 text-title" >
                    Perfiles
                </p>
                <div className="row">
                    <div className="col-3 d-flex align-items-center">
                        <Tooltip title="Permite visualizar Activos/Todos">
                            <Switch
                                size="small"
                                defaultChecked={only_active}
                                checkedChildren="Activos"
                                unCheckedChildren="Todos"
                                onChange={(only_active) => this.setState({ only_active })}
                            />
                        </Tooltip>
                    </div>
                    <div className="col-3 offset-6 col-md-1 offset-md-8 text-right">
                        {(permission_user && permission_user['4'] && permission_user['4']['7']) &&
                            <Tooltip title="Permite agregar un nuevo perfil al sistema...">
                                <div
                                    className="rounded-circle p-3 d-flex align-items-center justify-content-center btn-add"
                                    onClick={() => this.setState({ modal: true, contents: undefined })}
                                >
                                    <PlusOutlined style={{ color: "white", fontSize: 30 }} />
                                </div>
                            </Tooltip>
                        }
                    </div>
                </div>
                <Table
                    data={only_active ? _profiles : profiles}
                    arr={tb_profile}
                    height="70vh"
                    disabled={(permission_user && permission_user['4'] && permission_user['4']['7']) ? true : false}
                    handleEdit={(data) => this.setState({
                        modal: true,
                        contents: data
                    })}
                    handleChangeStatus={(data) => this.props.dispatch(addOrEditProfile({
                        ...data,
                        state: data.state === "1" ? 0 : 1,
                        id_user: session.id_user
                    }))}
                    handlePermission={(item) => this.setState({
                        item: { ...item, is_profile: true, profile: 'true' },
                        modal_permissions: true
                    })}
                />
            </div>
        )
    }

    handleModal() {
        const { modal, contents } = this.state;
        const { session, screen_mode } = this.props;
        return (
            <Rodal
                animation={contents ? "door" : "slideRight"}
                duration={500}
                visible={modal}
                onClose={() => this.setState({ modal: false })}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={200}
            >
                <h6>{contents ? "Editar" : "Nuevo"} perfil</h6>
                <Divider />
                <Forms
                    name="frm-doc"
                    objects={frm_doc}
                    initial={contents}
                    btn_title="Guardar"
                    handleSubmit={(values) => {
                        this.props.dispatch(addOrEditProfile({
                            ...contents,
                            ...values,
                            state: 1,
                            id_user: session.id_user
                        }));
                        this.setState({ modal: false });
                    }}
                />
            </Rodal>
        )
    }
}

function mapStateToProps(state) {
    const { _profile, _permission, _user } = state;
    const { profiles, _profiles } = _profile;
    const { permission_user } = _permission;
    const { session } = _user;
    return {
        profiles, _profiles,
        permission_user,
        session
    };
}

export default connect(mapStateToProps)(MProfile);
