import { message } from 'antd';
import moment from "moment";
import "moment/min/locales.min";

moment.locale('es');

export function setMessage(typo, menssage) {
    message.config({
        top: 0,
        duration: 3
    });
    message[typo](menssage);
}

export function validEmail(text) {
    let reg = /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/;
    return reg.test(text.trim());
}

export function validPassword(pass) {
    if (pass) {
        let score = 0;
        // award every unique letter until 5 repetitions
        let letters = {};
        for (let i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }
        // bonus points for mixing it up
        let variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        let variationCount = 0;
        for (let check in variations) {
            variationCount += (variations[check] === true) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;
        return (score > 30 && pass.length >= 8) ? true : false;
    }
    return false;
}

export function getScreenMode() {
    let screen_mode = false;
    if (window.innerWidth <= 550 || window.innerHeight <= 550) {
        screen_mode = true;
    }
    return screen_mode;
}

export function itHasLetters(texto) {
    let letras = "abcdefghyjklmnñopqrstuvwxyz";
    texto = texto.toLowerCase();
    for (let i = 0; i < texto.length; i++) {
        if (letras.indexOf(texto.charAt(i), 0) !== -1) {
            return true;
        }
    }
    return false;
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const newSelected = (id, object) => {
    let result = { ...object };
    if (result[id] !== undefined) {
        delete result[id];
    } else {
        result[id] = id;
    }
    return result;
}