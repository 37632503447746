import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Folder } from "@material-ui/icons";
import { Switch, Tooltip, Dropdown, Modal } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from "moment";
import "moment/min/locales.min";
import { MdArrowForwardIos } from "react-icons/md";
import { FcFolder } from "react-icons/fc";
import { HiOutlineChevronDoubleLeft } from "react-icons/hi";

import { Table } from "../components";
import { tb_documents } from "../assets/file/table.json";
import { addOrEditFile, addOrEditFolder, deleteFolderOrFile, getFilesAndFoldersById } from '../actions/folder.actions';
import { getAllPermissionsByUserId } from '../actions/permission.actions';

moment.locale('es');

class Folders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            only_active: true,
            modal_move: false,
            item: null
        }
    }

    componentDidMount() {
        const { session } = this.props;
        this.props.dispatch(getAllPermissionsByUserId(session));
    }

    render() {
        const { files_and_folders, _files_and_folders, path, session, permission_user } = this.props;
        const { only_active, modal_move } = this.state;
        return (
            <div className="container-home container-view">
                {modal_move && this.handleModalMove()}
                <div className="home-route">
                    {path && path.routes && this.handlePathNavigation()}
                </div>
                <div className="row">
                    <div className="col-6 text-left">
                        <Tooltip title="Permite visualizar Activos/Todos">
                            <Switch
                                size="small"
                                defaultChecked={only_active}
                                checkedChildren="Activos"
                                unCheckedChildren="Todos"
                                onChange={(only_active) => this.setState({ only_active })}
                            />
                        </Tooltip>
                    </div>
                    <div className="col-md-1 offset-md-5 col-3 offset-3">
                        <Dropdown
                            overlay={
                                <ul className="bg-white rounded p-3">
                                    <li
                                        className={`p-1 mt-1 ${this.handleValidPermission()}`}
                                        onClick={() => {
                                            if (this.handleValidPermission(false)) {
                                                this.props.handleOnClick({
                                                    modal: 'folder',
                                                    edit: false,
                                                    id_folder: path.id_folder,
                                                    id_datasheet: path.id_datasheet,
                                                })
                                            }
                                        }}
                                    >
                                        <Icon name='folder open outline' />
                                        <span>Nueva carpeta</span>
                                    </li>
                                    <li
                                        className={`p-1 mt-1 ${this.handleValidPermission()}`}
                                        onClick={() => {
                                            if (this.handleValidPermission(false)) {
                                                this.props.handleOnClick({
                                                    id_folder: path.id_folder,
                                                    id_datasheet: path.id_datasheet,
                                                    _file: null,
                                                    modal: 'file'
                                                })
                                            }
                                        }}
                                    >
                                        <Icon name='file alternate outline' />
                                        <span>Subir archivo</span>
                                    </li>
                                </ul>
                            }
                            placement='bottomLeft'
                        >
                            <div
                                className="rounded-circle p-3 d-flex align-items-center justify-content-center  btn-add"
                            >
                                <PlusOutlined style={{ color: "white", fontSize: 30 }} />
                            </div>
                        </Dropdown>
                    </div>
                </div>
                {permission_user &&
                    <Table
                        data={only_active ? _files_and_folders : files_and_folders}
                        arr={tb_documents}
                        height="75vh"
                        permission_user={permission_user}
                        handleViewFile={(item) => this.props.handleOnClick(item)}
                        handleEdit={item => {
                            if (!item.is_dir && item.datasheet && item.datasheet.due_date) {
                                item.datasheet.due_date = moment(item.datasheet.due_date, "YYYY-MM-DD");
                            }
                            this.props.handleOnClick({
                                ...item,
                                id_datasheet: item.id_datasheet ? item.id_datasheet : path.id_datasheet,
                                modal: item.is_dir ? 'folder' : 'file',
                                edit: true
                            })
                        }}
                        handleDelete={item => {
                            Modal.confirm({
                                title: "Eliminación de carpeta ",
                                content: `¿Estás seguro de querer eliminar ${item.is_dir ? 'esta carpeta' : 'este archivo'}? Todo su contenido se eliminará de manera permanente del sistema.`,
                                onOk: () => {
                                    this.props.dispatch(deleteFolderOrFile({
                                        id_user: session.id_user,
                                        id_folder: item.is_dir ? item.id_folder : 0,
                                        id_file: item.is_dir ? 0 : item.id_file,
                                        id_folder_dad: path.id_folder ? path.id_folder : 0
                                    }))
                                    this.setState({ show_menu: false });
                                },
                                cancelText: "Cancelar",
                                okText: "Sí"
                            })
                        }}
                        handleChangeStatus={(data) => {
                            const _data = {
                                ...data,
                                state: data.state === "1" ? 0 : 1,
                                id_user: session.id_user
                            };
                            if (data.is_dir) {
                                this.props.dispatch(addOrEditFolder(_data));
                            } else {
                                this.props.dispatch(addOrEditFile(_data));
                            }
                        }}
                        handleReminder={(item) => this.props.handleOnClick({
                            ...item,
                            modal: 'reminder',
                        })}
                        handleShare={(item) => this.props.handleOnClick({
                            ...item,
                            modal: "share"
                        })}
                        handleDefine={(item) => this.props.handleOnClick({
                            ...item,
                            modal: 'define',
                            id_folder_dad: path.id_folder ? path.id_folder : 0
                        })}
                        handleNav={(file) => this.props.dispatch(getFilesAndFoldersById({
                            id_folder: file.id_folder,
                            id_user: session.id_user
                        }))}
                        handleMove={(item) => this.props.handleOnClick({
                            ...item,
                            modal: 'move',
                            id_folder_dad: path.id_folder_dad ? path.id_folder_dad : 0,
                            id_datasheet: path.id_datasheet
                        })}
                    />
                }
            </div>
        )
    }

    handleValidPermission = (text = true) => {
        const { permission_user, path } = this.props;
        if (path && !path.id_folder) return text ? 'li-dropdown' : true;
        if (path && path.id_folder && permission_user && permission_user['1'] && permission_user['1']['1-' + path.id_folder]) return text ? 'li-dropdown' : true;
        return text ? 'li-dropdown-disabled' : false;
    }

    handlePathNavigation = () => {
        const width = window.innerWidth;
        const { path, session } = this.props;
        let dropdown = [];
        let routes = [];
        let cut = 0;

        if (width <= 576 && path.routes.length > 2) cut = 2;
        else if (width > 576 && width <= 768 && path.routes.length > 3) cut = 3;
        else if (width > 768 && width <= 992 && path.routes.length > 4) cut = 4;
        else if (width > 992 && width <= 1200 && path.routes.length > 5) cut = 5;
        else if (width > 1200 && path.routes.length > 6) cut = 6;
        else if (path.routes.length > 7) cut = 7;

        if (cut > 0) {
            dropdown = path.routes.slice(0, path.routes.length - cut);
            routes = path.routes.slice(path.routes.length - cut, path.routes.length);
        } else {
            routes = path.routes;
        }

        return (
            <>
                {dropdown.length > 0 &&
                    <Dropdown
                        overlay={
                            <ul className="p-2 bg-white">
                                {dropdown.map((item, i) => (
                                    <li
                                        key={i}
                                        className="d-flex align-items-center"
                                        style={{ listStyle: "none", cursor: "pointer" }}
                                        onClick={() => this.props.dispatch(getFilesAndFoldersById({
                                            id_folder: item.id_folder,
                                            id_user: session.id_user
                                        }))}
                                    >
                                        <FcFolder style={{ fontSize: 25, marginRight: 4 }} />
                                        <span className='text-title'>{item.name}</span>
                                    </li>
                                ))}
                            </ul>
                        }
                        placement='bottomRight'
                    >
                        <HiOutlineChevronDoubleLeft style={{ fontSize: 20, marginRight: 8 }} />
                    </Dropdown>
                }
                {routes.map((item, i) => (
                    <div
                        className="d-flex align-items-center"
                        key={i}
                        onClick={() => this.props.dispatch(getFilesAndFoldersById({
                            id_folder: item.id_folder,
                            id_user: session.id_user
                        }))}
                        style={{ cursor: "pointer" }}
                    >
                        <Tooltip title={item.name.length > 15 ? item.name : ""}>
                            <p className="p-0 m-0 text-title" style={{ fontSize: '1.2rem' }} >
                                {item.name.length > 15 ?
                                    item.name.slice(0, 15) + "..." :
                                    item.name}
                            </p>
                        </Tooltip>
                        {i === (routes.length - 1) ?
                            <Dropdown
                                disabled={path.pendings && path.pendings.length <= 0}
                                overlay={
                                    <ul className="p-2 bg-white border border-secondary rounded">
                                        {path.pendings && path.pendings.map((item, i) => (
                                            <li
                                                key={i}
                                                style={{ listStyle: "none" }}
                                            >
                                                <Icon
                                                    style={{ color: item.id_file > 0 ? "green" : "red" }}
                                                    name={item.id_file > 0 ? 'check' : 'times'}
                                                />
                                                <span className='text'>{item.document}</span>
                                            </li>
                                        ))}
                                    </ul>
                                }
                                placement='bottomLeft'
                            >
                                <Folder
                                    style={{
                                        fontSize: 20,
                                        color: path.color ? path.color : '#ffd54f'
                                    }}
                                />
                            </Dropdown>
                            : <MdArrowForwardIos style={{ fontSize: 20 }} />}
                    </div>
                ))}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { _folder, _user, _permission } = state;
    const { files_and_folders, _files_and_folders, path, _folders_user } = _folder;
    const { session } = _user;
    const { permission_user } = _permission;
    return {
        files_and_folders, _files_and_folders, path, _folders_user,
        session,
        permission_user
    };
}

export default connect(mapStateToProps)(Folders);
