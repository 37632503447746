import React, { Component } from 'react'
import { connect } from 'react-redux';
import Rodal from "rodal";
import { Divider, Tabs, Tree, Button } from "antd";
import { CheckBox, IndeterminateCheckBox, CropSquare } from "@material-ui/icons";

import { _POST } from '../services';
import { addOrEditPermission, getAllModules } from '../actions/permission.actions';
import { getAllFolders } from '../actions/folder.actions';
import { itHasLetters } from '../helpers';
import { Loading } from '.';

const { TabPane } = Tabs;
const { TreeNode } = Tree;

class ModalPrermission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assigned_permissions: null,
            selected_folders: [],
            _assigned_folder_permissions: null,
            assigned_folder_permissions: null,
            loading_perm: true,
            folder_permissions: [],
            id_folder: null,
            editing: false
        };
    }

    componentDidMount() {
        const { item } = this.props;
        this.props.dispatch(getAllModules());
        this.props.dispatch(getAllFolders());
        _POST(`permission.php?get_all_permission_by_id=true&profile=${item.profile}`, item).then(res => {
            this.setState({
                assigned_permissions: res.assigned_permissions,
                selected_folders: res.selected_folders,
                assigned_folder_permissions: res.assigned_folder_permissions,
                folder_permissions: res.folder_permissions
            }, () => this.setState({ loading_perm: false }));
        }).catch(err => console.error(err));
    }

    render() {
        const { item, modules, folders, session, screen_mode } = this.props;
        const { loading_perm, assigned_permissions, selected_folders, folder_permissions, _assigned_folder_permissions, id_folder, assigned_folder_permissions, editing } = this.state;
        const height = screen_mode ? (window.innerHeight < 500 ? window.innerHeight - 250 : 300) : 300;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={true}
                onClose={() => this.props.onClose()}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 600 ? window.innerWidth - 50 : 600) : 600}
                height={screen_mode ? (window.innerHeight < 500 ? window.innerHeight - 50 : 500) : 500}
            >
                <h6>Editar Permisos ({item.full_name ? item.full_name : item.name})</h6>
                <Divider style={{ margin: 0, padding: 0 }} />
                <center>
                    {loading_perm && <Loading type="true" />}
                </center>
                {!loading_perm &&
                    <>
                        <Tabs defaultActiveKey="1">
                            <TabPane
                                tab="Permisos"
                                key="1"
                                style={{ height: height, overflow: 'auto' }}
                            >
                                {assigned_permissions &&
                                    <Tree
                                        checkable
                                        defaultExpandedKeys={assigned_permissions}
                                        defaultSelectedKeys={assigned_permissions}
                                        defaultCheckedKeys={assigned_permissions}
                                        onCheck={(keys) => {
                                            let arr = [];
                                            for (const obj of keys) {
                                                if (!itHasLetters(obj)) {
                                                    arr.push(obj);
                                                }
                                            }
                                            this.setState({ assigned_permissions: arr });
                                        }}
                                        treeData={modules}
                                    />
                                }
                            </TabPane>
                            <TabPane
                                tab="Carpetas"
                                key="2"
                            >
                                <div className="d-flex justify-content-between">
                                    <div style={{ height: height, width: '70%', overflow: 'auto' }}>
                                        {!editing &&
                                            <Tree
                                                defaultExpandedKeys={selected_folders}
                                                onSelect={(val) => {
                                                    if (val.length > 0) {
                                                        this.setState({
                                                            id_folder: null,
                                                            _assigned_folder_permissions: null
                                                        }, () => {
                                                            const _assigned_folder_permissions = assigned_folder_permissions && assigned_folder_permissions[val[0]] ? assigned_folder_permissions[val[0]] : [];
                                                            this.setState({
                                                                id_folder: val[0],
                                                                _assigned_folder_permissions
                                                            })
                                                        })
                                                    }
                                                }}
                                            >
                                                {folders && folders.length > 0 && this.handleFolderRecursive(folders)}
                                            </Tree>
                                        }
                                    </div>
                                    {(id_folder && _assigned_folder_permissions) &&
                                        <Tree
                                            className="mr-3"
                                            checkable
                                            defaultExpandedKeys={['0']}
                                            defaultSelectedKeys={_assigned_folder_permissions}
                                            defaultCheckedKeys={_assigned_folder_permissions}
                                            onCheck={keys => this.setState({ editing: true }, () => this.handleSearchFolder(folders, keys))}
                                            treeData={folder_permissions}
                                        />
                                    }
                                </div>
                            </TabPane>
                        </Tabs>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                type="primary"
                                style={{ borderRadius: 10, marginTop: 20 }}
                                onClick={() => {
                                    this.props.dispatch(addOrEditPermission({
                                        ...item,
                                        assigned_permissions: JSON.stringify(assigned_permissions),
                                        assigned_folder_permissions: JSON.stringify(assigned_folder_permissions),
                                        _id_user: session.id_user,
                                        _id_profile: session.id_profile,
                                        name: item.full_name ? item.full_name : item.name
                                    }));
                                    this.props.onClose();
                                    this.setState({
                                        edit: false,
                                        assigned_permissions: [],
                                        assigned_folder_permissions: null,
                                        id_folder: null
                                    });
                                }}
                            >
                                Guardar
                            </Button>
                        </div>
                    </>
                }
            </Rodal>
        );
    }

    handleFolderRecursive = (folders) => {
        return folders.map(item => {
            return (
                <TreeNode
                    title={
                        <div className="d-flex align-items-center">
                            {this.handleValidPermissions(item.key)}
                            {item.title}
                        </div>
                    }
                    key={item.key}
                >
                    {item.children && item.children.length > 0 && this.handleFolderRecursive(item.children)}
                </TreeNode>
            )
        });
    }

    handleValidPermissions = (key) => {
        const { assigned_folder_permissions } = this.state;
        const arr = assigned_folder_permissions && assigned_folder_permissions[key] ? assigned_folder_permissions[key] : [];
        if (arr.length >= 3) {
            return <CheckBox style={{ color: '#1890ff', fontSize: 20 }} />;
        } else if (arr.length > 0) {
            return <IndeterminateCheckBox style={{ color: '#1890ff', fontSize: 20 }} />;
        } else {
            return <CropSquare style={{ color: '#e0e0e0', fontSize: 24 }} />;
        }
    }

    handleSearchFolder = (folders, keys) => {
        const { id_folder, assigned_folder_permissions, selected_folders } = this.state;
        folders.forEach(folder => {
            if (folder.key === id_folder) {
                const { _assigned_folder_permissions, _selected_folders } = this.handleFolderRecursiveSelected(keys, [folder], assigned_folder_permissions, selected_folders);
                this.setState({
                    assigned_folder_permissions: _assigned_folder_permissions,
                    selected_folders: _selected_folders,
                    editing: false
                });
                return;
            } else if (folder.children && folder.children.length > 0) {
                this.handleSearchFolder(folder.children, keys);
            }
        });
    }

    handleFolderRecursiveSelected = (keys, folders, assigned_folder_permissions, selected_folders) => {
        folders.forEach(folder => {
            assigned_folder_permissions = {
                ...assigned_folder_permissions,
                [folder.key]: keys
            };
            selected_folders = selected_folders.filter(item => item !== folder.key);
            if (keys.length > 0) {
                selected_folders.push(folder.key);
            }
            if (folder.children && folder.children.length > 0) {
                const { _assigned_folder_permissions, _selected_folders } = this.handleFolderRecursiveSelected(keys, folder.children, assigned_folder_permissions, selected_folders);
                assigned_folder_permissions = _assigned_folder_permissions;
                selected_folders = _selected_folders;
            }
        });
        return {
            _assigned_folder_permissions: assigned_folder_permissions,
            _selected_folders: selected_folders
        };
    }
}

function mapStateToProps(state) {
    const { _folder, _permission, _user } = state;
    const { session } = _user;
    const { folders } = _folder;
    const { modules } = _permission;
    return { folders, modules, session };
}

export default connect(mapStateToProps)(ModalPrermission);
