import React, { Component } from 'react';
import { Form, Button, Select, DatePicker, Switch } from "antd";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { validPassword, validEmail } from '../helpers';

const { Option } = Select;


export default class Forms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show_pass: false,
            undefined_time: true
        }
        this.formGeneral = React.createRef();
    }

    render() {
        const { name, initial, objects, disabled, btn_title, hiden_button } = this.props;
        return (
            <Form
                ref={this.formGeneral}
                initialValues={initial}
                name={name}
                layout="vertical"
                size={"middle"}
                scrollToFirstError
                onFinish={(values) => this.props.handleSubmit(values)}
                onFinishFailed={(errorInfo) => console.error('failure', errorInfo)}
            >
                <div className="row">
                    {objects && objects.map((item, i) => (
                        item.type === 1 ? this.handleInputText(item, i) :
                            item.type === 2 ? this.handlePassword(item, i) :
                                item.type === 3 ? this.handleEmail(item, i) :
                                    item.type === 4 ? this.handleSelect(item, i) :
                                        item.type === 5 ? this.handleDate(item, i) :
                                            this.handleSwitch(item, i)
                    ))}
                </div>
                {!hiden_button &&
                    <Button
                        disabled={disabled}
                        type="primary"
                        block
                        style={{ borderRadius: 10 }}
                        htmlType="submit"
                    >
                        {btn_title}
                    </Button>
                }
            </Form>
        );
    }

    handleInputText = (item, i) => (
        <div className={item.col ? 'col-md-' + item.col : 'col-12'} key={i}>
            <Form.Item
                name={item.name}
                rules={[{ required: item.required, message: `¡Campo requerido (${item.label.toLowerCase()})!` }]}
            >
                <TextField
                    label={item.label}
                    variant="outlined"
                    type="text"
                    size="small"
                    className="input-text"
                    inputProps={{ maxLength: item.limit }}
                    multiline={item.multiline}
                    rows={item.row ? item.row : 3}
                />
            </Form.Item>
        </div>
    );

    handlePassword = (item, i) => {
        const { show_pass } = this.state;
        return (
            <div className={item.col ? 'col-md-' + item.col : 'col-12'} key={i}>
                <Form.Item
                    name={item.name}
                    rules={[{
                        required: true, validator: async (_, pass) => {
                            if (item.compare && pass !== this.formGeneral.current.getFieldValue(item.compare)) {
                                return Promise.reject("¡La contraseña no es la misma!");
                            } else if (!pass) {
                                return Promise.reject("Contraseña requerida...");
                            } else if (item.valid && !validPassword(pass)) {
                                return Promise.reject("¡Contraseña poco segura!");
                            } else {
                                return Promise.resolve();
                            }
                        }
                    }]}
                >
                    <FormControl
                        size="small"
                        variant="outlined"
                        className="input-text"
                    >
                        <InputLabel htmlFor="outlined-adornment-password">
                            {item.label}
                        </InputLabel>
                        <OutlinedInput
                            type={show_pass ? "text" : "password"}
                            inputProps={{ maxLength: 45 }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => this.setState({ show_pass: !show_pass })}
                                        edge="end"
                                        size="small"
                                    >
                                        {show_pass ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />
                    </FormControl>
                </Form.Item>
            </div>
        );
    }

    handleEmail = (item, i) => (
        <div className={item.col ? 'col-md-' + item.col : 'col-12'} key={i}>
            <Form.Item
                name={item.name}
                rules={[{
                    required: item.required, validator: (_, correo) => {
                        if (item.required === true) {
                            if (!correo || correo === '') {
                                return Promise.reject("¡Por favor ingrese un correo!");
                            } else if (validEmail(correo)) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject("¡El correo ingresado no es valido!");
                            }
                        } else {
                            return Promise.resolve();
                        }
                    }
                }]}
            >
                <TextField
                    label={item.label}
                    variant="outlined"
                    type="email"
                    size="small"
                    className="input-text"
                    inputProps={{ maxLength: 45 }}
                />
            </Form.Item>
        </div>
    );

    handleSelect = (item, i) => {
        const { options, disabled_select } = this.props;
        return (
            <div className={item.col ? 'col-md-' + item.col : 'col-12'} key={i}>
                <Form.Item
                    label={item.label}
                    name={item.name}
                    rules={[{ required: item.required, message: `¡Campo requerido (${item.label.toLowerCase()})!` }]}
                >
                    <Select
                        disabled={disabled_select}
                        className="input-text"
                        showSearch
                        mode={item.multiple ? "multiple" : undefined}
                        autoClearSearchValue
                        placeholder="Selecciones una opcion"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value) => {
                            if (item.change) {
                                this.props.onChangeSelect(value);
                            }
                        }}
                    >
                        {(options && options[item.option]) && options[item.option].map((_item, j) => (
                            <Option key={j} value={_item[item.name]}>{_item.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        );
    }

    handleDate = (item, i) => {
        const { undefined_time } = this.state;
        return (
            <div className={item.col ? 'col-md-' + item.col : 'col-12'} key={i}>
                <Form.Item
                    label={item.label}
                    name={item.name}
                    rules={[{ required: item.required, message: `¡Campo requerido (${item.label.toLowerCase()})!` }]}
                >
                    <DatePicker
                        className="input-text"
                        format="DD-MM-YYYY"
                        disabled={!item.disabled || !undefined_time ? false : true}
                        placeholder="Seleccione una fecha"
                        disabledDate={d => d.isBefore(new Date())}
                    />
                </Form.Item>
            </div>
        );
    }

    handleSwitch = (item, i) => (
        <div className={item.col ? 'col-md-' + item.col : 'col-12'} key={i}>
            <Form.Item
                label={item.label}
                name={item.name}
                rules={[{ required: item.required, message: `¡Campo requerido (${item.label.toLowerCase()})!` }]}
            >
                <center>
                    <Switch
                        defaultChecked={true}
                        checkedChildren="Si"
                        unCheckedChildren="No"
                        onChange={(undefined_time) => this.setState({ undefined_time })}
                    />
                </center>
            </Form.Item>
        </div>
    );
}