import { _REMINDER } from "../reducers/constants";
import { _GET, _POST } from "../services";

const _PATH = "reminder.php?";
function request() { return { type: _REMINDER.REQUEST_RMN }; }
function failure(err) { return { type: _REMINDER.FAILURE_RMN, err }; }
function success(types, message) { return { type: _REMINDER.SUCCESS_RMN, types, message }; }

export function getAllReminders() {
    return dispatch => {
        dispatch(request());
        _GET(_PATH + "get_all=true").then(res => {
            dispatch({ type: _REMINDER.GET_ALL_RMN, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function getRemindersByUser(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "get_by_user_id=true", data).then(res => {
            dispatch({ type: _REMINDER.GET_ALL_RMN_USER, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function addOrEditReminder(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "add_or_edit=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err) dispatch(getRemindersByUser(data));
        }).catch(err => dispatch(failure(err)));
    }
}

export function getAllReminderTimes() {
    return dispatch => {
        _GET(_PATH + "get_all_times=true").then(res => {
            dispatch({ type: _REMINDER.GET_ALL_REMINDER_TIMES, res });
        }).catch(err => dispatch(failure(err)));
    }
}
