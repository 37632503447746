import React, { Component } from 'react';
import { connect } from 'react-redux';
import Rodal from "rodal";
import { Button, Divider, Upload, Modal as ModalAndt } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ShareOutlined, CloudUploadOutlined } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import moment from "moment";
import "moment/min/locales.min";
import FileView from "react-file-viewer";

import { Forms } from "./";
import { frm_share, frm_restore, frm_reminder, frm_doc, frm_define, frm_move } from "../assets/file/form.json";
import { shareFile } from '../actions/util.actions';
import { addOrEditUser } from '../actions/user.actions';
import { addOrEditReminder } from '../actions/reminder.actions';
import { addOrEditFile, addOrEditFolder, moveFileOrFolder } from '../actions/folder.actions';

moment.locale('es');

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            _file: "",
            modal_confir: false,
            values: null,
            id_folder: null
        }
    }

    componentDidMount() {
        const { item } = this.props;
        console.log(item);
    }

    render() {
        const { item, user } = this.props;
        const { modal_confir } = this.state;
        return (
            <>
                {modal_confir &&
                    <ModalAndt
                        visible={modal_confir}
                        title={
                            <div className="d-flex align-items-center">
                                <ExclamationCircleOutlined style={{ color: "orange" }} />&nbsp;
                                Mover Carperta
                            </div>}
                        footer={[
                            <Button
                                type="ghost"
                                danger onClick={() => this.setState({ modal_confir: false })}
                            >
                                Cancelar
                            </Button>,
                            <Button
                                type="primary"
                                danger onClick={() => this.handleSubmitMove(0)}
                            >
                                No
                            </Button>,
                            <Button
                                type="primary"
                                onClick={() => this.handleSubmitMove(1)}
                            >
                                Sí
                            </Button>
                        ]}
                        onCancel={() => this.setState({ modal_confir: false })}
                    >
                        <p>{`Estas a punto de mover la carpeta "${item.name}", ¿quieres que se apliquen las fichas de la carpeta destino?`}</p>
                    </ModalAndt>
                }
                {item && item.modal && item.modal === "share" && this.ModalShare()}
                {((item && item.modal && item.modal === "restore") || (user && user.restore === "1")) && this.ModalRestorePassword()}
                {item && item.modal && item.modal === "reminder" && this.ModalReminder()}
                {item && item.modal && item.modal === "folder" && this.ModalFolder()}
                {item && item.modal && item.modal === "file" && this.ModalFile()}
                {item && item.modal && item.modal === "view_file" && this.ModalViewFile()}
                {item && item.modal && item.modal === "define" && this.ModalDefine()}
                {item && item.modal && item.modal === "move" && this.ModalMove()}
            </>
        );
    }

    ModalShare = () => {
        const { item, _users, session, screen_mode } = this.props;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={true}
                onClose={() => this.props.onClose()}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={screen_mode ? (window.innerHeight < 430 ? window.innerHeight - 50 : 430) : 430}
            >
                <div className="d-flex align-items-center">
                    <div
                        style={{
                            background: "#1890ff",
                            height: 30,
                            width: 30,
                            borderRadius: 15,
                            marginRight: 4
                        }}
                    >
                        <ShareOutlined style={{ color: "white", marginLeft: 3 }} />
                    </div>
                    <h6> Archivo a compartir: <b>{item.name}</b></h6>
                </div>
                <Divider />
                <Forms
                    name="frm-share"
                    objects={frm_share}
                    initial={{
                        expiration_date: moment().add(1, 'days'),
                        undefined_time: true
                    }}
                    btn_title="Guardar"
                    handleSubmit={(values) => {
                        this.props.dispatch(shareFile({
                            ...item,
                            ...values,
                            id_user: session.id_user,
                            undefined_time: values.undefined_time ? 1 : 0,
                            expiration_date: values.expiration_date.format("YYYY-MM-DD")
                        }));
                        this.props.onClose();
                    }}
                    options={{ _users }}
                />
            </Rodal>
        );
    }

    ModalRestorePassword = () => {
        const { item, session, screen_mode, user } = this.props;
        const _item = item ? item : user;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={true}
                closeMaskOnClick
                onClose={() => this.props.onClose()}
                showCloseButton={item && item.profile}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={screen_mode ? (window.innerHeight < 350 ? window.innerHeight - 50 : 350) : 350}
            >
                <h6 style={{ width: "90%" }}>
                    {item && item.profile ? "Cambio de contraseña," : "La contraseña actual es por defecto, "} por favor ingresa una nueva contraseña</h6>
                <Divider />
                <Forms
                    name="frm-folder"
                    objects={frm_restore}
                    btn_title="Guardar"
                    handleSubmit={(values) => {
                        this.props.dispatch(addOrEditUser({
                            ..._item,
                            ...values,
                            change: true,
                            _id_user: session.id_user
                        }));
                        this.props.onClose();
                    }}
                />
            </Rodal>
        )
    }

    ModalReminder = () => {
        const { item, session, screen_mode, reminders_times } = this.props;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={true}
                onClose={() => this.props.onClose()}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={screen_mode ? (window.innerHeight < 460 ? window.innerHeight - 50 : 460) : 460}
            >
                <div style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}>
                    <h6>{item && item.id_reminder ? "Editar" : "Nuevo"} recordatorio</h6>
                    <Divider />
                    <Forms
                        name="frm-define"
                        objects={frm_reminder}
                        initial={item && item.id_reminder ? item : undefined}
                        btn_title="Guardar"
                        handleSubmit={(values) => {
                            this.props.dispatch(addOrEditReminder({
                                ...item,
                                ...values,
                                reminder_date: values.reminder_date.format("YYYY-MM-DD"),
                                id_user: session.id_user
                            }));
                            this.props.onClose();
                        }}
                        options={{ reminders_times }}
                    />
                </div>
            </Rodal>
        );
    }

    ModalFolder = () => {
        const { item, screen_mode, session } = this.props;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={true}
                onClose={() => this.props.onClose()}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={200}
            >
                <h6>{item && item.edit ? "Editar" : "Nuevo"} carpeta</h6>
                <Divider />
                <Forms
                    name="frm-folder"
                    objects={frm_doc}
                    initial={item && item.edit ? item : undefined}
                    btn_title="Guardar"
                    handleSubmit={(values) => {
                        this.props.dispatch(addOrEditFolder({
                            ...item,
                            ...values,
                            ...session,
                            id_folder: item.edit ? item.id_folder : 0,
                            id_folder_dad: item.edit ? item.id_folder_dad : item.id_folder
                        }));
                        this.props.onClose();
                    }}
                />
            </Rodal>
        )
    }

    ModalFile = () => {
        const { item, datasheet, screen_mode, required_file, code } = this.props;
        const { _file, name } = this.state;
        const propsUpload = {
            className: "upload-list-inline",
            onRemove: () => this.setState({ _file: null }),
            onChange: info => {
                let name_file = info.file.name;
                const arr = name_file.split(".");
                info.file.status = "done";
                this.setState({ name: arr[0] });
            },
            multiple: false,
            listType: "picture",
            customRequest: ({ file }) => this.setState({ _file: file }),
        };
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={true}
                onClose={() => this.props.onClose()}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={screen_mode ? (window.innerHeight < 400 ? window.innerHeight - 50 : 400) : 400}
            >
                {item &&
                    <div style={{ height: "100%", overflowX: "hidden", overflowY: "auto", padding: "0 5px" }}>
                        <h6>{item.id_file ? "Editar" : "Nuevo"} archivo</h6>
                        <Divider />
                        <Upload {...propsUpload}>
                            <Button
                                type="primary"
                                ghost
                                shape="round"
                            >
                                <CloudUploadOutlined />&nbsp;Archivos
                            </Button>
                        </Upload>
                        <div className="mt-4">
                            {item.code &&
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    size="small"
                                    className="input-text"
                                    value={code}
                                    disabled={true}
                                />
                            }
                        </div>
                        <div className="mt-4 mb-4">
                            <TextField
                                label="Nombre del archivo"
                                variant="outlined"
                                type="text"
                                size="small"
                                value={name !== "" ? name : (item && item.edit ? item.name : name)}
                                className="input-text"
                                inputProps={{ maxLength: 45 }}
                                onChange={(evt) => {
                                    const { value } = evt.target;
                                    this.setState({ name: value });
                                }}
                            />
                        </div>
                        {datasheet ?
                            <Forms
                                name="frm-datasheet"
                                objects={datasheet.form}
                                initial={item.datasheet ? item.datasheet : undefined}
                                btn_title="Guardar"
                                disabled={!item ? (_file === null ? true : false) : false}
                                options={{ _documents: required_file }}
                                handleSubmit={(values) => this.handleSubmitFile(values)}
                            /> :
                            <Button
                                type="primary"
                                style={{ borderRadius: 10, marginTop: 5 }}
                                block
                                onClick={() => this.handleSubmitFile()}
                            >
                                Guardar
                            </Button>
                        }
                    </div>
                }
            </Rodal>
        );
    }

    handleSubmitFile = (values) => {
        const { item, session, code } = this.props;
        const { name, _file } = this.state;
        if (values && values.due_date) {
            values.due_date = values.due_date.format("YYYY-MM-DD");
        }
        this.props.dispatch(addOrEditFile({
            ...item,
            name: name !== "" ? name : item.name,
            code,
            datasheet: values ? JSON.stringify(values) : "{}",
            due_date: values && values.due_date ? values.due_date : "",
            id_document: values && values.id_document ? values.id_document : 0,
            id_user: session.id_user,
            state: 1,
            _file
        }));
        this.props.onClose();
        this.setState({
            name: "",
            _file: null
        });
    }

    ModalViewFile = () => {
        const { item } = this.props;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={true}
                onClose={() => this.props.onClose()}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10, padding: 0 }}
                width={window.innerWidth - 50}
                height={window.innerHeight - 50}
            >
                {item &&
                    <div className="modal-view-file">
                        {(/\.(jpg|png|gif|jpeg|tif)$/i).test(item.path) ?
                            <div className="row h-100 justify-content-center align-items-center">
                                <img src={item.url} height="100%" alt="img" />
                            </div> :
                            (/\.(docx|xlsx|csv)$/i).test(item.path) ?
                                <div style={{ width: '100%', height: '100%', padding: 10 }}>
                                    <FileView
                                        fileType={item.type}
                                        filePath={item.url}
                                        onError={(e) => console.log("err", e)}
                                    />
                                </div> :
                                <embed
                                    src={`${item.url}${item.download ? "" : "#toolbar=0"}`}
                                    type={`application/${item.type}`}
                                    width="100%"
                                    height="100%"
                                />
                        }
                    </div>
                }
            </Rodal>
        );
    }

    ModalDefine = () => {
        const { screen_mode, item, _datasheets, session } = this.props;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={true}
                onClose={() => this.props.onClose()}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={250}
            >
                <h6>Definir contenido</h6>
                <Divider />
                {_datasheets &&
                    <Forms
                        name="frm-define"
                        objects={frm_define}
                        initial={item}
                        disabled={!_datasheets || _datasheets.length === 0}
                        btn_title="Guardar"
                        handleSubmit={(values) => {
                            this.props.dispatch(addOrEditFolder({
                                ...item,
                                ...values,
                                ...session,
                                id_folder_dad: item.id_folder_dad ? item.id_folder_dad : 0
                            }));
                            this.props.onClose();
                        }}
                        options={{ _datasheets: [..._datasheets, { name: '-- SIN FICHA --', id_datasheet: 0 }] }}
                    />
                }
            </Rodal>
        );
    }

    ModalMove = () => {
        const { screen_mode, item, _folders_user } = this.props;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={true}
                onClose={() => this.props.onClose()}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={250}
            >
                {item &&
                    <>
                        <h6>{`Mover: (${item.name}) a `}</h6>
                        <Divider />
                        <Forms
                            name="frm-move"
                            objects={frm_move}
                            btn_title="Guardar"
                            options={{ _folders_user }}
                            handleSubmit={(values) => {
                                if (item.is_dir) {
                                    this.setState({ values, modal_confir: true });
                                } else {
                                    this.setState({ values }, () => this.handleSubmitMove(0));
                                }
                            }}
                        />
                    </>
                }
            </Rodal>
        )
    }

    handleSubmitMove = (persist) => {
        const { item, session, _folders_user } = this.props;
        const { values } = this.state;
        const { id_folder } = values;
        const arr = _folders_user.filter(item => {
            return id_folder === item.id_folder
        });
        const _values = arr[0];
        const data = {
            is_dir: item.is_dir ? 1 : 0,
            id_file: item.id_file ? item.id_file : 0,
            id_folder: item.id_folder,
            id_folder_dad: _values.id_folder,
            id_user: session.id_user,
            id_profile: session.id_profile,
            name: item.name,
            persist
        }
        this.props.dispatch(moveFileOrFolder(data));
        this.setState({ modal_confir: false });
        this.props.onClose();
    }
}

function mapStateToProps(state) {
    const { _user, _datasheet, _folder, _reminder } = state;
    const { session, _users, user } = _user;
    const { datasheet, _datasheets, code, required_file } = _datasheet;
    const { _folders_user, folders } = _folder;
    const { reminders_times } = _reminder;
    return {
        session, _users, user,
        datasheet, _datasheets, code, required_file,
        _folders_user, folders,
        reminders_times
    }
}

export default connect(mapStateToProps)(Modal);
