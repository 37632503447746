import React, { Component } from 'react';
import { Tooltip, Modal, Avatar, Menu, Button, Dropdown } from 'antd';
import {
    FileExcelFilled, FilePdfFilled, FileTextOutlined, FileImageOutlined, FileWordFilled,
    FileUnknownOutlined, FolderOpenOutlined
} from "@ant-design/icons";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import {
    LockOpen, Lock, Edit, Settings, FormatLineSpacingTwoTone,
    Folder, CalendarToday, ShareOutlined, CloudDownloadOutlined, DeleteOutline
} from "@material-ui/icons";
import { _SERVER } from '../services';

export default class Table extends Component {
    render() {
        const { data, arr, height } = this.props;
        return (
            <div className="row">
                <div
                    className='ag-theme-balham' style={{ backgroundColor: "", width: '100%', height: height ? height : '80vh' }}
                >
                    <AgGridReact
                        enableSorting={true}
                        animateRows={true}
                        enableColResize={true}
                        rowSelection='single'
                        onGridReady={(params) => { params.api.sizeColumnsToFit(); }}
                        rowData={data}
                    >
                        {arr &&
                            arr.map((res, i) => (
                                res.type === 1 ? this.handleText(res, i)
                                    : (res.type === 2) ? this.handleStatus(res, i)
                                        : (res.type === 3) ? this.handleImgAndText(res, i)
                                            : (res.type === 4) ? this.handleIconAndText(res, i)
                                                : this.handleOptios(res, i)
                            ))
                        }
                    </AgGridReact>
                </div>
            </div>
        );
    }

    handleText = (res, i) => (
        <AgGridColumn
            key={i}
            headerName={res.header}
            field={res.value}
            sortable={res.filter}
            filter={res.filter}
            floatingFilter={res.filter}
            resizable={true}
            cellRendererFramework={(param) => {
                return (
                    <Tooltip title={param.value}>
                        {param.value}
                    </Tooltip>
                );
            }}
        />
    )

    handleImgAndText = (res, i) => (
        <AgGridColumn
            key={i}
            headerName={res.header}
            field="data"
            sortable={res.filter}
            filter={res.filter}
            floatingFilter={res.filter}
            resizable={true}
            cellRendererFramework={(param) => {
                const val = param.data[res.value];
                return (
                    <Tooltip title={val}>
                        <div className="d-flex align-items-center">
                            <Avatar
                                src={_SERVER._PUBLIC + "img/" + param.data.image}
                                size={25}
                            />
                            {val}
                        </div>
                    </Tooltip>
                )
            }}
        />
    )

    handleIconAndText = (res, i) => {
        const { download } = this.props;
        return (
            <AgGridColumn
                key={i}
                headerName={res.header}
                field="data"
                sortable={res.filter}
                filter={res.filter}
                floatingFilter={res.filter}
                resizable={true}
                minWidth={res.width || 200}
                cellRendererFramework={(param) => {
                    const val = param.data[res.value];
                    return (
                        <Tooltip title={val}>
                            <div
                                className="d-flex align-items-center"
                                onClick={() => {
                                    if (param.data.state === "1") {
                                        if (!param.data.is_dir) {
                                            this.props.handleViewFile({
                                                ...param.data,
                                                modal: "view_file",
                                                url: `${_SERVER._PUBLIC}Carpetas/${param.data.path}`,
                                                download
                                            })
                                        } else {
                                            this.props.handleNav(param.data)
                                        }
                                    }
                                }}
                            >
                                {this.handleGetIcon(param.data.path, param.data.is_dir, param.data.color)}
                                {val}
                            </div>
                        </Tooltip>
                    )
                }}
            />
        )
    }

    handleStatus = (res, i) => (
        <AgGridColumn
            key={i}
            headerName={res.header}
            field={res.value}
            sortable={res.filter}
            filter={res.filter}
            floatingFilter={res.filter}
            cellStyle={(param) => (param.value) === "1" ? { color: 'green' } : { color: 'red' }}
            resizable={true}
            width={res.width || 150}
            cellRendererFramework={(param) => (
                <div
                    className={
                        `text-center 
                                ${param.value === "1" ? 'text-info' : 'text-danger'}`
                    }
                >
                    {param.value === "1" ?
                        <div>
                            <LockOpen style={{ fontSize: "0.8rem" }} />
                            Activo
                        </div> :
                        <div>
                            <Lock style={{ fontSize: "0.8rem" }} />
                            Inactivo
                        </div>
                    }
                </div>
            )}
        />
    )

    handleOptios = (res, i) => {
        const { disabled, permission_user, download } = this.props;
        return (
            <AgGridColumn
                key={i}
                headerName={res.header}
                field="data"
                resizable={true}
                cellRendererFramework={(param) => (
                    <Dropdown
                        onVisibleChange={(flag) => this.setState({ see_notification: flag })}
                        overlay={() => (
                            <Menu>
                                {(res.share && !param.data.is_dir) &&
                                    <Menu.Item>
                                        <Button
                                            className="btn-round"
                                            type="link"
                                            size="small"
                                            icon={<ShareOutlined />}
                                            onClick={() => this.props.handleShare(param.data)}
                                            disabled={param.data.state === "0"}
                                        >
                                            Compartir
                                        </Button>
                                    </Menu.Item>
                                }
                                {(res.define && param.data.is_dir) &&
                                    <Menu.Item>
                                        <Button
                                            className="btn-round"
                                            type="link"
                                            size="small"
                                            icon={<Settings />}
                                            onClick={() => this.props.handleDefine(param.data)}
                                            disabled={(param.data.state === "1" &&
                                                (param.data.id_folder && permission_user['1'] && permission_user['1'][`1-${param.data.id_folder}`])) ? false : true}
                                        >
                                            Definir contenido
                                        </Button>
                                    </Menu.Item>
                                }
                                {(res.download && !param.data.is_dir) &&
                                    <Menu.Item>
                                        <Button
                                            className="btn-round"
                                            type="link"
                                            size="small"
                                            icon={<CloudDownloadOutlined />}
                                            disabled={(param.data.state === "1" &&
                                                ((param.data.id_folder && permission_user && permission_user['1'] && permission_user['1'][`10-${param.data.id_folder}`]) || download)) ? false : true}
                                        >
                                            <a
                                                href={_SERVER._URL + "util.php?download_file=true&id_file=" + param.data.id_file}
                                                target="_blank"
                                                rel="noreferrer"
                                                disabled={(param.data.state === "1" &&
                                                    ((param.data.id_folder && permission_user && permission_user['1'] && permission_user['1'][`10-${param.data.id_folder}`]) || download)) ? false : true}
                                            >
                                                Descargar
                                            </a>
                                        </Button>
                                    </Menu.Item>
                                }
                                {res.edit &&
                                    <Menu.Item>
                                        <Button
                                            className="btn-round"
                                            type="link"
                                            size="small"
                                            icon={<Edit />}
                                            onClick={() => this.props.handleEdit(param.data)}
                                            disabled={(param.data.state === "1" &&
                                                ((param.data.id_folder && permission_user && permission_user['1'] && permission_user['1'][`1-${param.data.id_folder}`]) || disabled)) ? false : true}
                                        >
                                            Editar
                                        </Button>
                                    </Menu.Item>
                                }
                                {res.delete &&
                                    <Menu.Item>
                                        <Button
                                            className="btn-round"
                                            type="link"
                                            size="small"
                                            icon={<DeleteOutline />}
                                            onClick={() => this.props.handleDelete(param.data)}
                                            disabled={(param.data.state === "1" &&
                                                (param.data.id_folder && permission_user && permission_user['1'] && permission_user['1'][`12-${param.data.id_folder}`])) ? false : true}
                                        >
                                            Elminar
                                        </Button>
                                    </Menu.Item>
                                }
                                {res.status &&
                                    <Menu.Item>
                                        <Button
                                            className="btn-round"
                                            type="link"
                                            size="small"
                                            icon={param.data.state === "1" ? <Lock /> : <LockOpen />}
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: `${param.data.state === "1" ? "Inactivar" : "Activar"}`,
                                                    content: `¿Estás seguro de que quieres ${param.data.state === "1" ? "inactivar" : "activar"}?`,
                                                    okText: "SI",
                                                    cancelText: "CANCELAR",
                                                    onOk: () => this.props.handleChangeStatus(param.data)
                                                });
                                            }}
                                            disabled={((param.data.id_folder && permission_user['1'] && permission_user['1'][`1-${param.data.id_folder}`]) || disabled) ? false : true}
                                        >
                                            {param.data.state === "1" ? "Inactivar" : "Activar"}
                                        </Button>
                                    </Menu.Item>
                                }
                                {res.permission &&
                                    <Menu.Item>
                                        <Button
                                            className="btn-round"
                                            type="link"
                                            size="small"
                                            icon={<Settings />}
                                            onClick={() => this.props.handlePermission(param.data)}
                                            disabled={param.data.state === "0"}
                                        >
                                            Permisos
                                        </Button>
                                    </Menu.Item>
                                }
                                {res.reminder &&
                                    <Menu.Item>
                                        <Button
                                            className="btn-round"
                                            type="link"
                                            size="small"
                                            icon={<CalendarToday />}
                                            onClick={() => this.props.handleReminder(param.data)}
                                            disabled={(param.data.state === "1" &&
                                                ((param.data.id_folder && permission_user && permission_user['1'] && permission_user['1'][`1-${param.data.id_folder}`]) || disabled)) ? false : true}
                                        >
                                            Recordatorio
                                        </Button>
                                    </Menu.Item>
                                }
                                {res.move &&
                                    <Menu.Item>
                                        <Button
                                            className="btn-round d-flex"
                                            type="link"
                                            size="small"
                                            icon={<FolderOpenOutlined style={{ fontSize: 20 }} />}
                                            onClick={() => this.props.handleMove(param.data)}
                                            disabled={(param.data.state === "1" && ((!param.data.is_dir && param.data.id_folder === null) ||
                                                (param.data.id_folder && permission_user && permission_user['1'] && permission_user['1'][`1-${param.data.id_folder}`]))) ? false : true}
                                        >
                                            Mover a
                                        </Button>
                                    </Menu.Item>
                                }
                            </Menu>
                        )}
                        placement="bottomLeft"
                    >
                        <Button
                            type="link"
                            block
                            icon={<FormatLineSpacingTwoTone style={{ color: "#03A9F4" }} />}
                        />
                    </Dropdown>
                )}
            />
        )
    }

    handleGetIcon = (name, is_dir = false, color) => {
        if (is_dir) {
            return (<Folder style={{ color: color ? color : "#ffd54f" }} />);
        } else if ((/\.(xsl|xslx|xlsx|xlsm|xltx|xltm|xlsb|xlam)$/i).test(name)) {
            return (<FileExcelFilled style={{ color: "green" }} />);
        } else if ((/\.(jpg|png|gif|jpeg|tif)$/i).test(name)) {
            return (<FileImageOutlined />);
        } else if ((/\.(odt|doc|docx|docm|dotx|dotm)$/i).test(name)) {
            return (<FileWordFilled style={{ color: "#4fc3f7" }} />);
        } else if ((/\.(pdf)$/i).test(name)) {
            return (<FilePdfFilled style={{ color: "red" }} />);
        } else if ((/\.(txt)$/i).test(name)) {
            return (<FileTextOutlined />);
        } else {
            return (<FileUnknownOutlined />);
        }
    }
}
