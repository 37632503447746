import React, { Component } from 'react';
import { connect } from "react-redux";
import { Divider, Switch, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Rodal from "rodal";

import { addOrEditDocument, getAllDocuments } from '../actions/document.actions';
import { getAllPermissionsByUserId } from "../actions/permission.actions";
import { getScreenMode } from '../helpers';
import { Forms, Table } from "../components";
import { frm_doc } from "../assets/file/form.json";
import { tb_catalogue } from "../assets/file/table.json";

class MDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            screen_mode: false,
            contents: undefined,
            only_active: true
        }
    }

    componentDidMount() {
        const { session } = this.props;
        this.props.dispatch(getAllDocuments());
        this.props.dispatch(getAllPermissionsByUserId(session));
        this.setState({ screen_mode: getScreenMode() });
        window.addEventListener('resize', () => this.setState({ screen_mode: getScreenMode() }));
    }

    render() {
        const { documents, permission_user, session, _documents } = this.props;
        const { modal, only_active } = this.state;
        return (
            <div className="container-view">
                {modal && this.handleModal()}
                <p className="h3 p-0 m-0 text-title" >
                    Documentos
                </p>
                <div className="row">
                    <div className="col-9 col-md-11 d-flex align-items-center">
                        <Tooltip title="Permite visualizar Activos/Todos">
                            <Switch
                                size="small"
                                defaultChecked={only_active}
                                checkedChildren="Activos"
                                unCheckedChildren="Todos"
                                onChange={(only_active) => this.setState({ only_active })}
                            />
                        </Tooltip>
                    </div>
                    <div className="col-md-1 col-3">
                        {(permission_user && permission_user['5'] && permission_user['2']['3']) &&
                            <Tooltip title="Permite agregar un nuevo documeto al catálogo ...">
                                <div
                                    className="rounded-circle p-3 d-flex align-items-center justify-content-center btn-add"
                                    onClick={() => this.setState({
                                        modal: true,
                                        contents: undefined
                                    })}
                                >
                                    <PlusOutlined style={{ color: "white", fontSize: 30 }} />
                                </div>
                            </Tooltip>
                        }
                    </div>
                </div>
                <Table
                    data={only_active ? _documents : documents}
                    arr={tb_catalogue}
                    height="70vh"
                    disabled={(permission_user && permission_user['5'] && permission_user['2']['3']) ? true : false}
                    handleEdit={(data) => this.setState({
                        modal: true,
                        contents: data
                    })}
                    handleChangeStatus={(data) => this.props.dispatch(addOrEditDocument({
                        ...data,
                        state: data.state === "1" ? 0 : 1,
                        id_user: session.id_user
                    }))}
                />
            </div>
        )
    }

    handleModal() {
        const { modal, screen_mode, contents } = this.state;
        const { session } = this.props;
        return (
            <Rodal
                animation={contents ? "door" : "slideRight"}
                duration={500}
                visible={modal}
                onClose={() => this.setState({ modal: false })}
                closeMaskOnClick
                showCloseButton={true}
                customStyles={{ borderRadius: 10 }}
                width={screen_mode ? (window.innerWidth < 400 ? window.innerWidth - 50 : 400) : 400}
                height={200}
            >
                <h6>{contents ? "Editar" : "Nuevo"} Documento</h6>
                <Divider />
                <Forms
                    name="frm-doc"
                    objects={frm_doc}
                    initial={contents}
                    btn_title="Guardar"
                    handleSubmit={(values) => {
                        this.props.dispatch(addOrEditDocument({
                            ...contents,
                            ...values,
                            id_user: session.id_user
                        }));
                        this.setState({ modal: false });
                    }}
                />
            </Rodal>
        )
    }
}

function mapStateToProps(state) {
    const { _document, _user, _permission } = state;
    const { documents, _documents } = _document;
    const { session } = _user;
    const { permission_user } = _permission;
    return {
        documents, _documents,
        session,
        permission_user
    };
}

export default connect(mapStateToProps)(MDocument);
