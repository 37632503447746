import { _PERMISSION } from "../reducers/constants";
import { _GET, _POST } from "../services";
import { getFilesAndFoldersById, getFoldersByUserId } from "./folder.actions";

const _PATH = "permission.php?";
function request(empty = false) { return { type: _PERMISSION.REQUEST_PERM, empty }; }
function failure(err) { return { type: _PERMISSION.FAILURE_PERM, err }; }
function success(types, message) { return { type: _PERMISSION.SUCCESS_PERM, types, message }; }

export function getAllModules() {
    return dispatch => {
        dispatch(request());
        _GET(_PATH + "get_all_modules=true").then(res => {
            dispatch({ type: _PERMISSION.GET_ALL_MODULES, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function addOrEditPermission(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "add_or_edit=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if ((data.id_user && data.id_user === data._id_user) ||
                (data.id_profile && data.id_profile === data._id_profile)) {
                const user = { id_user: data._id_user };
                dispatch(getAllPermissionsByUserId(user, true));
            }
        }).catch(err => dispatch(failure(err)));
    }
}

export function getAllPermissionsByUserId(data, get_folders = false) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "get_by_user_id=true", data).then(res => {
            dispatch({ type: _PERMISSION.GET_ALL_PERM_BY_USER_ID, res });
            if (get_folders) {
                dispatch(getFoldersByUserId(data));
                dispatch(getFilesAndFoldersById(data))
            };
        }).catch(err => dispatch(failure(err)));
    }
}