import { _FOLDER } from "../reducers/constants";
import { _GET, _POST } from "../services";
import { getPendingsByUserId, getShareFilesByUserId } from "./util.actions";
import { getRemindersByUser } from "./reminder.actions";
import { getAllPermissionsByUserId } from "./permission.actions";

const _PATH = "folder.php?";
function request() { return { type: _FOLDER.REQUEST_FOLDER }; }
function failure(err) { return { type: _FOLDER.FAILURE_FOLDER, err }; }
function success(types, message) { return { type: _FOLDER.SUCCESS_FOLDER, types, message }; }

export function getAllFolders() {
    return dispatch => {
        dispatch(request());
        _GET(_PATH + "get_all=true").then(res => {
            dispatch({ type: _FOLDER.GET_ALL_FOLDER, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function getFoldersByUserId(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "get_by_user_id=true", data).then(res => {
            dispatch({ type: _FOLDER.GET_FOLDER_BY_USER_ID, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function getFilesAndFoldersById(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "get_files_and_folders_by_id=true", data).then(res => {
            dispatch({ type: _FOLDER.GET_FILES_AND_FOLDERS_BY_ID, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function addOrEditFolder(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "add_or_edit=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err) {
                data.id_folder = data.state === 1 ? data.id_folder : data.id_folder_dad;
                dispatch(getAllPermissionsByUserId(data, true));
                dispatch(getPendingsByUserId(data));
                dispatch(getRemindersByUser(data));
            }
        }).catch(err => dispatch(failure(err)));
    }
}

export function addOrEditFile(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "add_or_edit_file=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err) {
                if (data.id_share_file) {
                    dispatch(getShareFilesByUserId(data));
                } else {
                    dispatch(getFoldersByUserId(data));
                    dispatch(getFilesAndFoldersById(data));
                    dispatch(getPendingsByUserId(data));
                    dispatch(getRemindersByUser(data));
                }
            };
        }).catch(err => dispatch(failure(err)));
    }
}

export function moveFileOrFolder(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "move_file_or_folder=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err) {
                dispatch(getFoldersByUserId(data));
                dispatch(getFilesAndFoldersById(data));
            }
        }).catch(err => dispatch(failure(err)));
    }
}

export function deleteFolderOrFile(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "delete=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err) {
                const _data = {
                    id_user: data.id_user,
                    id_folder: data.id_folder_dad
                }
                dispatch(getFoldersByUserId(_data));
                dispatch(getFilesAndFoldersById(_data));
                dispatch(getPendingsByUserId(_data));
                dispatch(getRemindersByUser(_data));
            };
        }).catch(err => dispatch(failure(err)));
    }
}
