import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Modal } from "antd";
import { UpOutlined, DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Icon } from "semantic-ui-react";

import { deleteFolderOrFile, getFilesAndFoldersById, moveFileOrFolder } from '../actions/folder.actions';
import { newSelected } from '../helpers';
import { Carpeta } from "./View";
import img_home from "../assets/img/_home.png";

let _ID = 0;
class NavDocs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: {},
            folder: null,
            y_pos: 0,
            show_menu: false,
            item: null,
            source: null,
            modal_confir: false
        }
    }

    render() {
        const { folders_user, focus, screen_mode } = this.props;
        const { show_menu, modal_confir, source } = this.state;
        return (
            <div className="nav-docs-menu">
                {modal_confir &&
                    <Modal
                        visible={modal_confir}
                        title={
                            <div className="d-flex align-items-center">
                                <ExclamationCircleOutlined style={{ color: "orange" }} />&nbsp;
                                Mover Carperta
                            </div>}
                        footer={[
                            <Button
                                type="ghost"
                                danger onClick={() => this.setState({ modal_confir: false, item: null, source: null })}
                            >
                                Cancelar
                            </Button>,
                            <Button
                                type="primary"
                                danger onClick={() => this.handleSubmitMove(0)}
                            >
                                No
                            </Button>,
                            <Button
                                type="primary"
                                onClick={() => this.handleSubmitMove(1)}
                            >
                                Sí
                            </Button>
                        ]}
                        onCancel={() => this.setState({ modal_confir: false })}
                    >
                        <p>{`Estas a punto de mover una carpeta "${source.name}", ¿quieres que se apliquen las fichas de la carpeta destino?`}</p>
                    </Modal>
                }
                {show_menu && this.handleRenderMenuRight()}
                <Link
                    to="/"
                    onClick={() => this.props.handleOnChange("pendientes")}
                    style={{ textDecoration: "none" }}
                >
                    <div
                        className="w-100 pt-3 pb-3 text-center"
                        style={{
                            color: "#204387",
                            background: focus === 'pendientes' ? "#fdfdfd" : "white"
                        }}
                    >
                        <img
                            src={img_home}
                            alt="home"
                            height="40"
                        />
                        &nbsp;
                        {!screen_mode &&
                            <span><b>Pendientes</b></span>
                        }
                    </div>
                </Link>
                <div className="nav-documents">
                    {folders_user && this.renderSubMenu({
                        id_folder: 0,
                        id_folder_dad: 0,
                        name: "Carpetas",
                        color: "#204387",
                        children: folders_user
                    })}
                </div>
                <Link
                    to="/archivos-compartidos"
                    onClick={() => this.props.handleOnChange("share")}
                >
                    <div className={`ml-3 d-flex justify-content-start ${focus === 'share' ? 'share-focus' : 'share'}`}>
                        <Carpeta
                            width={25}
                            height={25}
                            fill="#204387"
                        />
                        &nbsp;
                        {!screen_mode &&
                            <span>Archivos Compartidos</span>
                        }
                    </div>
                </Link>
            </div>
        )
    }

    handleSubmitMove = (persist) => {
        const { item, source } = this.state;
        const { session } = this.props;
        this.props.dispatch(moveFileOrFolder({
            is_dir: 1,
            id_folder: source.id_folder,
            id_folder_dad: item.id_folder,
            id_datasheet: item.id_datasheet,
            id_user: session.id_user,
            name: source.name,
            persist
        }));
        this.setState({ modal_confir: false, item: null, source: null });
    }

    renderSubMenu = (item) => {
        const { selected } = this.state;
        const { session, permission_user, focus, screen_mode } = this.props;
        return (
            <div
                className="submenu"
                key={item.id_folder}
                onDragOver={(env) => env.preventDefault()}
                onDrop={(e) => {
                    if (_ID === 0) {
                        _ID = item.id_folder;
                        const source = JSON.parse(e.dataTransfer.getData("source"));
                        if (item.id_folder !== source.id_folder_dad) {
                            if (permission_user && permission_user['1'] && permission_user['1'][`1-${source.id_folder}`]) {
                                this.setState({ modal_confir: true, item, source });
                            } else {
                                Modal.warning({
                                    title: "Mover Directorio",
                                    content: "No cuentas con los permisos requeridos para realizar la siguiente acción..."
                                });
                            }
                        }
                        setTimeout(() => {
                            _ID = 0;
                        }, 1000);
                    }
                }}
            >
                <div
                    draggable={item.id_folder === 0 ? false : true}
                    onDragStart={(env) => env.dataTransfer.setData("source", JSON.stringify(item))}
                    className={`${focus === item.id_folder ? 'title-focus' : 'title'} d-flex align-items-center justify-content-between`}
                    onClick={() => {
                        this.props.dispatch(getFilesAndFoldersById({
                            id_folder: item.id_folder,
                            id_folder_dad: item.id_folder_dad,
                            id_user: session.id_user
                        }));
                        this.setState({
                            selected: newSelected(item.id_folder, selected),
                            show_menu: false
                        });
                        this.props.handleOnChange(item.id_folder);
                        this.props.handleNavClick();
                    }}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        if (e.nativeEvent.which === 3) {
                            this.setState({
                                y_pos: `${e.pageY - 100}px`,
                                show_menu: true,
                                folder: item
                            });
                        }
                    }}
                >
                    <div className="d-flex align-items-center mr-5">
                        <Carpeta
                            width={25}
                            height={25}
                            fill={item.color ? item.color : "#ffff00"}
                        />
                        &nbsp;
                        {!screen_mode &&
                            <span>{item.name}</span>
                        }

                    </div>
                    {(selected && selected[item.id_folder] !== undefined) ?
                        <UpOutlined style={{ fontSize: 10, paddingRight: 5 }} /> :
                        <DownOutlined style={{ fontSize: 10, paddingRight: 5 }} />}
                </div>
                {(selected && selected[item.id_folder] !== undefined) &&
                    item.children.map(_item => (
                        this.renderSubMenu(_item)
                    ))}
            </div>
        );
    }

    handleRenderMenuRight = () => {
        const { y_pos, folder } = this.state;
        const { session } = this.props;
        const optios = [
            {
                id: 1,
                name: 'Definir contenido',
                icon: 'setting',
                modal: 'define',
                permission: 1
            },
            {
                id: 2,
                name: 'Editar',
                icon: 'edit alternate outline',
                modal: 'folder',
                permission: 1
            },
            {
                id: 7,
                name: 'Eliminar',
                icon: 'trash alternate outline',
                modal: 'folder',
                permission: 12
            },
            {
                id: 3,
                name: 'Nueva carpeta',
                icon: 'folder outline',
                modal: 'folder',
                permission: 1
            },
            {
                id: 4,
                name: 'Mover a',
                icon: 'folder open outline',
                modal: 'move',
                permission: 1
            },
            {
                id: 5,
                name: 'Recordatorio',
                icon: 'calendar outline',
                modal: 'reminder',
                permission: 1
            },
            {
                id: 6,
                name: 'Subir archivo',
                icon: 'file alternate outline',
                modal: 'file',
                permission: 1
            }
        ];
        return (
            <ul
                className="menu-click-right"
                style={{
                    top: y_pos,
                    left: 100
                }}
            >
                {optios.map(item => (
                    <li
                        key={item.id}
                    >
                        <Button
                            type="text"
                            onClick={() => {
                                if (item.id === 7) {
                                    Modal.confirm({
                                        title: "Eliminación de carpeta ",
                                        content: "¿Estás seguro de querer eliminar esta carpeta? Todo su contenido se eliminará de manera permanente del sistema.",
                                        onCancel: () => this.setState({ show_menu: false }),
                                        onOk: () => {
                                            this.props.dispatch(deleteFolderOrFile({
                                                id_user: session.id_user,
                                                id_folder: folder.id_folder,
                                                id_folder_dad: folder.id_folder_dad
                                            }))
                                            this.setState({ show_menu: false });
                                        },
                                        cancelText: "Cancelar",
                                        okText: "Sí"
                                    })
                                } else {
                                    this.props.handleOnClick({
                                        ...folder,
                                        modal: item.modal,
                                        edit: item.id === 2 ? true : false,
                                        is_dir: 1
                                    });
                                    this.setState({ show_menu: false });
                                }
                            }}
                            disabled={this.handleValidDisabled(item)}
                        >
                            <Icon name={item.icon} />
                            <span className='text'>{item.name}</span>
                        </Button>
                    </li>
                ))}
            </ul>
        )
    }

    handleValidDisabled = (item) => {
        const { folder } = this.state;
        const { permission_user } = this.props;
        if (folder.id_folder === 0 && (item.id === 3 || item.id === 5)) return false;
        if (folder && folder.id_folder &&
            permission_user && permission_user['1'] &&
            permission_user['1'][`${item.permission}-${folder.id_folder}`]) return false;
        return true;
    }
}

function mapStateToProps(state) {
    const { _folder, _user, _permission } = state;
    const { folders_user } = _folder;
    const { session } = _user;
    const { permission_user } = _permission;
    return {
        folders_user,
        session,
        permission_user
    };
}

export default connect(mapStateToProps)(NavDocs);
