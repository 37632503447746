import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import "moment/min/locales.min";
import { Switch, Tooltip, Button } from 'antd';
import { ReloadOutlined } from "@ant-design/icons";

import { getShareFilesByUserId } from '../actions/util.actions';
import { addOrEditFile } from "../actions/folder.actions";
import { getAllPermissionsByUserId } from '../actions/permission.actions';
import { Table } from "../components";
import { tb_share } from "../assets/file/table.json";

moment.locale('es');

class Share extends Component {

    constructor(props) {
        super(props);
        this.state = {
            only_active: true
        }
    }

    componentDidMount() {
        const { session } = this.props;
        this.props.dispatch(getAllPermissionsByUserId(session));
        this.handleGetAll();
    }
    handleGetAll = () => this.props.dispatch(getShareFilesByUserId(this.props.session));

    render() {
        const { session, shared_files, permission_user, _shared_files } = this.props;
        const { only_active } = this.state;
        return (
            <div className="container-view">
                <p className="h3 p-0 m-0 text-title" >
                    Archivos compartidos
                </p>
                <div className="d-flex justify-content-between align-items-center">
                    <Tooltip title="Permite visualizar Activos/Todos">
                        <Switch
                            size="small"
                            defaultChecked={only_active}
                            checkedChildren="Activos"
                            unCheckedChildren="Todos"
                            onChange={(only_active) => this.setState({ only_active })}
                        />
                    </Tooltip>
                    <Tooltip title="Permite recargar el contenido de la tabla">
                        <Button
                            type="dashed"
                            size="small"
                            icon={<ReloadOutlined />}
                            onClick={() => this.handleGetAll()}
                            className="d-flex align-items-center mr-3"
                        >
                            Actualizar
                        </Button>
                    </Tooltip>
                </div>
                <Table
                    data={only_active ? _shared_files : shared_files}
                    arr={tb_share}
                    height="75vh"
                    disabled={(permission_user && permission_user['1']) ? true : false}
                    download={(permission_user && permission_user['1']) ? true : false}
                    handleViewFile={(item) => this.props.handleOnClick(item)}
                    handleEdit={item => {
                        if (item.datasheet && item.datasheet.due_date) {
                            item.datasheet.due_date = moment(item.datasheet.due_date, "YYYY-MM-DD");
                        }
                        this.props.handleOnClick({
                            ...item,
                            modal: 'file',
                            edit: true
                        })
                    }}
                    handleChangeStatus={(data) => {
                        const arr = data.name.split(".");
                        this.props.dispatch(addOrEditFile({
                            ...data,
                            name: arr[0],
                            _name: data.name,
                            state: data.state === "1" ? 0 : 1,
                            id_user: session.id_user
                        }));
                    }}
                    handleReminder={(item) => this.props.handleOnClick({
                        ...item,
                        modal: 'reminder',
                    })}
                    handleShare={(item) => this.props.handleOnClick({
                        ...item,
                        modal: "share"
                    })}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { _user, _util, _permission } = state;
    const { session, _users } = _user;
    const { shared_files, _shared_files } = _util;
    const { permission_user } = _permission;
    return {
        session, _users,
        shared_files, _shared_files,
        permission_user
    }
}

export default connect(mapStateToProps)(Share);
