import { combineReducers, applyMiddleware, createStore } from "redux";
import trunkMiddleware from "redux-thunk";

import {
    _USER, _PROFILE, _PERMISSION, _DOCUMENT, _DATASHEET,
    _REMINDER, _FOLDER, _UTIL
} from "./constants";
import { setMessage } from "../helpers";

const _user = (state = {}, action) => {
    switch (action.type) {
        case _USER.REQUEST:
            return {
                ...state,
                loading: true,
                loading_login: action.loading_login
            };
        case _USER.SUCCESS:
            setMessage(action.types, action.message);
            return {
                ...state,
                loading: false
            }
        case _USER.FAILURE:
            setMessage('error', action.err.toString());
            return {
                ...state,
                loading_login: false,
                loading: false
            }
        case _USER.LOGIN:
            return {
                ...state,
                session: action.session,
                login: action.login,
                loading_login: false,
                loading: false
            }
        case _USER.GET_ALL_USERS:
            return {
                ...state,
                users: action.res.users,
                _users: action.res._users,
                loading: false,
            }
        case _USER.GET_USER_BY_ID:
            return {
                ...state,
                user: action.res.user
            }
        default:
            return state;
    }
}

const _profile = (state = {}, action) => {
    switch (action.type) {
        case _PROFILE.REQUEST_PRO:
            return {
                ...state,
                loading_pro: true
            };
        case _PROFILE.SUCCESS_PRO:
            setMessage(action.types, action.message);
            return {
                ...state,
                loading_pro: false
            }
        case _PROFILE.FAILURE_PRO:
            setMessage('error', action.err.toString());
            return {
                ...state,
                loading_pro: false
            }
        case _PROFILE.GET_ALL_PRO:
            return {
                ...state,
                profiles: action.res.profiles,
                _profiles: action.res._profiles,
                loading_pro: false,
            }
        default:
            return state;
    }
}

const _permission = (state = {}, action) => {
    switch (action.type) {
        case _PERMISSION.REQUEST_PERM:
            return {
                ...state,
                loading_perm: true,
                permission_user: action.empty ? undefined : state.permission_user
            };
        case _PERMISSION.SUCCESS_PERM:
            setMessage(action.types, action.message);
            return {
                ...state,
                loading_perm: false
            }
        case _PERMISSION.FAILURE_PERM:
            setMessage('error', action.err.toString());
            return {
                ...state,
                loading_perm: false
            }
        case _PERMISSION.GET_ALL_MODULES:
            return {
                ...state,
                modules: action.res.modules,
                loading_perm: false,
            }
        case _PERMISSION.GET_ALL_PERM_BY_USER_ID:
            return {
                ...state,
                permission_user: action.res.permission_user
            }
        default:
            return state;
    }
}

const _document = (state = {}, action) => {
    switch (action.type) {
        case _DOCUMENT.REQUEST_DOC:
            return {
                ...state,
                loading_doc: true,
                search_results: []
            };
        case _DOCUMENT.SUCCESS_DOC:
            setMessage(action.types, action.message);
            return {
                ...state,
                loading_doc: false
            }
        case _DOCUMENT.FAILURE_DOC:
            setMessage('error', action.err.toString());
            return {
                ...state,
                loading_doc: false
            }
        case _DOCUMENT.GET_ALL_DOCS:
            return {
                ...state,
                documents: action.res.documents,
                _documents: action.res._documents,
                loading_doc: false
            }
        default:
            return state;
    }
}

const _datasheet = (state = {}, action) => {
    switch (action.type) {
        case _DATASHEET.REQUEST_DAT:
            return {
                ...state,
                loading_dat: true
            };
        case _DATASHEET.SUCCESS_DAT:
            setMessage(action.types, action.message);
            return {
                ...state,
                loading_dat: false
            }
        case _DATASHEET.FAILURE_DAT:
            setMessage('error', action.err.toString());
            return {
                ...state,
                loading_dat: false
            }
        case _DATASHEET.GET_ALL_DAT:
            return {
                ...state,
                datasheets: action.res.datasheets,
                _datasheets: action.res._datasheets,
                loading_dat: false
            }
        case _DATASHEET.GET_FORM_PROPERTIES:
            return {
                ...state,
                form_properties: action.res.form_properties,
                loading_dat: false
            }
        case _DATASHEET.GET_DATASHET:
            return {
                ...state,
                datasheet: action.res.datasheet,
                required_file: action.res.required_file,
                code: action.res.code,
                loading_dat: false
            }
        default:
            return state;
    }
}

const _reminder = (state = {}, action) => {
    switch (action.type) {
        case _REMINDER.REQUEST_RMN:
            return {
                ...state,
                loading_rmn: true
            };
        case _REMINDER.SUCCESS_RMN:
            setMessage(action.types, action.message);
            return {
                ...state,
                loading_rmn: false
            }
        case _REMINDER.FAILURE_RMN:
            setMessage('error', action.err.toString());
            return {
                ...state,
                loading_rmn: false
            }
        case _REMINDER.GET_ALL_RMN:
            return {
                ...state,
                reminders: action.res.reminders,
                loading_rmn: false
            }
        case _REMINDER.GET_ALL_RMN_USER:
            return {
                ...state,
                reminders_user: action.res.reminders_user,
                _reminders_user: action.res._reminders_user,
                reminders_automatic: action.res.reminders_automatic,
                loading_rmn: false
            }
        case _REMINDER.GET_ALL_REMINDER_TIMES:
            return {
                ...state,
                reminders_times: action.res.reminders_times
            }
        default:
            return state;
    }
}

const _folder = (state = {}, action) => {
    switch (action.type) {
        case _FOLDER.REQUEST_FOLDER:
            return {
                ...state,
                loading_folder: true
            }
        case _FOLDER.FAILURE_FOLDER:
            setMessage('error', action.err.toString());
            return {
                ...state,
                loading_folder: false
            }
        case _FOLDER.SUCCESS_FOLDER:
            setMessage(action.types, action.message);
            return {
                ...state,
                loading_folder: false
            }
        case _FOLDER.GET_ALL_FOLDER:
            return {
                ...state,
                folders: action.res.folders,
                loading_folder: false
            }
        case _FOLDER.GET_FOLDER_BY_USER_ID:
            return {
                ...state,
                folders_user: action.res.folders_user,
                _folders_user: action.res._folders_user,
                loading_folder: false
            }
        case _FOLDER.GET_FILES_AND_FOLDERS_BY_ID:
            return {
                ...state,
                files_and_folders: action.res.files_and_folders,
                _files_and_folders: action.res._files_and_folders,
                path: action.res.path,
                loading_folder: false
            }
        default:
            return state;
    }
}

const _util = (state = {}, action) => {
    switch (action.type) {
        case _UTIL.REQUEST_UTIL:
            return {
                ...state,
                loading_util: true
            }
        case _UTIL.FAILURE_UTIL:
            setMessage('error', action.err.toString());
            return {
                ...state,
                loading_util: false
            }
        case _UTIL.SUCCESS_UTIL:
            setMessage(action.types, action.message);
            return {
                ...state,
                loading_util: false
            }
        case _UTIL.GET_PENDINGS_BY_USER_ID:
            return {
                ...state,
                pending_by_user: action.res.pending_by_user,
                new_or_edited_files: action.res.new_or_edited_files,
                loading_util: false
            }
        case _UTIL.GET_SHARED_FILES_BY_USER_ID:
            return {
                ...state,
                shared_files: action.res.shared_files,
                _shared_files: action.res._shared_files,
                loading_util: false
            }
        case _UTIL.GET_SEARCH_FILES_AND_FOLDERS:
            return {
                ...state,
                search_results: action.res.search_results,
                loading_util: false
            }
        case _UTIL.GET_ALL_RECORDS:
            return {
                ...state,
                records: action.res.records,
                loading_util: false
            }
        default:
            return state;
    }
}

export const store = createStore(
    combineReducers({
        _user,
        _profile,
        _document,
        _datasheet,
        _reminder,
        _permission,
        _folder,
        _util
    }),
    applyMiddleware(
        trunkMiddleware
    )
);