import { _DATASHEET } from "../reducers/constants";
import { _GET, _POST } from "../services";

const _PATH = "datasheet.php?";
function request() { return { type: _DATASHEET.REQUEST_DAT }; }
function failure(err) { return { type: _DATASHEET.FAILURE_DAT, err }; }
function success(types, message) { return { type: _DATASHEET.SUCCESS_DAT, types, message }; }

export function getAllDatasheet() {
    return dispatch => {
        dispatch(request());
        _GET(_PATH + "get_all=true").then(res => {
            dispatch({ type: _DATASHEET.GET_ALL_DAT, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function addOrEditDatasheet(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "add_or_edit_datasheet=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err) dispatch(getAllDatasheet());
        }).catch(err => dispatch(failure(err)));
    }
}

export function getAllFormProperties() {
    return dispatch => {
        dispatch(request());
        _GET(_PATH + "get_form_property=true").then(res => {
            dispatch({ type: _DATASHEET.GET_FORM_PROPERTIES, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function getDatasheetById(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "get_by_id=true", data).then(res => {
            dispatch({ type: _DATASHEET.GET_DATASHET, res });
        }).catch(err => dispatch(failure(err)));
    }
}