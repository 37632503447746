import React from 'react';
import { Table, Dropdown, Icon } from "semantic-ui-react";
import { Tooltip } from 'antd';

export const TableSemantic = ({
    title, headers, arr_body, onClickRow, dropdown_menu,
    onClickEdit, onClickDelete, onClickProceedings, color
}) => {

    const handleCutPath = (path) => {
        const arr = String(path).split("/");
        if (arr.length > 2) return ".../" + arr.slice(arr.length - 2, arr.length).join("/");
        return path;
    }

    return (
        <div className="col-md-6">
            <p className="h4 pt-1 m-0 text-title" >
                {title}
            </p>
            <Table striped unstackable size="small" compact="very" color={color || "orange"}>
                <Table.Header>
                    <Table.Row>
                        {headers.map((header, index) => (
                            <Table.HeaderCell
                                key={index}
                                width={header.width}
                            >
                                {header.title}
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {arr_body.map((body, i) => (
                        <Table.Row key={i} onClick={() => onClickRow(body)} >
                            {headers.map((header, j) => (
                                <Table.Cell
                                    key={j}
                                    width={header.width}
                                    error={header.error}
                                    warning={header.warning}
                                >
                                    {(dropdown_menu && header.id === 'menu') ?
                                        <Dropdown
                                            floating
                                            icon="bars"
                                            labeled
                                        >
                                            <Dropdown.Menu style={{ right: 0, left: "auto" }}>
                                                <Dropdown.Item
                                                    onClick={() => onClickEdit(body)}
                                                >
                                                    <Icon name='edit outline' />
                                                    <span className='text'>Editar</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => onClickDelete(body)}
                                                >
                                                    <Icon name='trash alternate' />
                                                    <span className='text'>Eliminar</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => onClickProceedings(body)}
                                                >
                                                    <Icon name='folder open outline' />
                                                    <span className='text'>Expediente</span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> :
                                        <Tooltip title={header.tooltip ? body[header.mensaje] : null}>
                                            {header.id === 'index' ? (i + 1) :
                                                (header.id === 'folder' ? handleCutPath(body[header.id]) : body[header.id])}
                                        </Tooltip>
                                    }
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};
