import { Link } from "react-router-dom";
import { Badge, Dropdown, Upload, Avatar } from "antd";
import { Notifications } from "@material-ui/icons";
import { SearchOutlined, LoadingOutlined, CameraOutlined } from "@ant-design/icons";

import { _SERVER } from "../services";
import logo from "../assets/img/logo.png";
import logo_horizontal from "../assets/img/logo_horizontal.png";

export const Footer = ({ color, title }) => (
    <footer
        className="text-center text-lg-start w-100"
    >
        {title ?
            <span style={{ color: color, fontSize: "1.2rem" }}>© Todos los derechos reservados</span> :
            <span>
                © 2021, diseñado por&nbsp;
                <a
                    href="https://ocacall.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    OCA
                </a>
            </span>
        }
    </footer>
);

export const NavBar = (props) => {
    const { user, screen_mode, reminders_user, search_text, loading_util, search_results } = props;
    const colors = ['white', '#8FA1C3'];
    const propsUpload = {
        accept: ".png,.jpg,.jpeg",
        multiple: false,
        onChange: (info) => info.file.status = "done",
        customRequest: async ({ _, __, file }) => props.handleRequest(file),
        showUploadList: false
    };
    return (
        <div className="navbar-container">
            <nav className="navbar w-100">
                <Link className="navbar-brand" to="/">
                    <img
                        src={screen_mode ? logo : logo_horizontal}
                        alt="logo"
                        height={70}
                    />
                </Link>
                <div
                    className="navbar-nav m-auto"
                    style={{ width: screen_mode ? "40%" : "50%" }}
                >
                    <Dropdown
                        overlay={
                            <div className="w-100 p-3 bg-white" style={{ borderRadius: 25 }}>
                                {(!search_results || search_results.length <= 0) &&
                                    <p className="text-center p-2">
                                        <b>No se encontraron archivos o directorios...</b>
                                    </p>
                                }
                                <ul className="h-100 ul-content-search">
                                    {search_results && search_results.map((item, i) => (
                                        <li
                                            className="w-100 mb-1"
                                            key={i}
                                            style={{ backgroundColor: colors[i % 2] }}
                                            onClick={() => props.onSearch(item)}
                                        >
                                            <p className="p-0 m-0"><b>Directorio: {item.folder}</b></p>
                                            <p className="p-0 m-0">Archivo: {item.file}</p>
                                            <p className="p-0 m-0">Usuario: {item.full_name}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        }
                        placement='bottomLeft'
                    >
                        <div className="input-group">
                            <div className="input-group-prepend" style={{ border: "none" }}>
                                <span
                                    className="input-group-text input-search p-0"
                                    id="basic-addon1"
                                >
                                    <div className="rounded-circle input-search-icon">
                                        {loading_util ? <LoadingOutlined /> :
                                            <SearchOutlined />}
                                    </div>
                                </span>
                            </div>
                            <input
                                type="text"
                                className="form-control input-search"
                                placeholder="Buscar por nombre, código o usuario"
                                aria-label="search_text"
                                name="search_text"
                                aria-describedby="basic-addon1"
                                value={search_text}
                                onChange={(env) => props.onChange(env)}
                                autoComplete="off"
                            />
                        </div>
                    </Dropdown>
                </div>
                <div className="form-inline my-2 my-lg-0 d-flex">
                    <Badge
                        count={reminders_user ? reminders_user.length : 0}
                        offset={[-13, 7]}
                        size="small"
                    >
                        <Dropdown
                            overlay={
                                <div className="reminders">
                                    <p className="text-center mt-3">
                                        <b>Notificaciones</b>
                                    </p>
                                    <ul className="h-100 container-reminders">
                                        {reminders_user && reminders_user.map((item, i) => (
                                            <li
                                                key={i}
                                                style={{ backgroundColor: colors[i % 2], cursor: "pointer" }}
                                                onClick={() => props.handleReminder(item)}
                                            >
                                                <p className="m-0 p-0"><b>{item.title}</b></p>
                                                <div
                                                    className="d-flex align-items-center"
                                                >
                                                    <Notifications
                                                        style={{
                                                            fontSize: 25,
                                                            color: i % 2 ? colors[0] : colors[1],
                                                            margin: "0 5px",
                                                            width: '10%'
                                                        }}
                                                    />
                                                    <p
                                                        style={{ width: "80%" }}
                                                    >
                                                        {item.message}
                                                    </p>
                                                    <IconDelete
                                                        height="20"
                                                        style={{ width: '10%' }}
                                                        fill={i % 2 ? colors[0] : colors[1]}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            props.handleReminder({ ...item, delete: true })
                                                        }}
                                                    ></IconDelete>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    {(reminders_user && reminders_user.length > 0) &&
                                        <div className="text-right">
                                            <button
                                                className="btn"
                                                onClick={() => props.handleReminder({ delete: true })}
                                            >
                                                <IconDelete
                                                    height="20"
                                                    fill="#8FA1C3"
                                                ></IconDelete>
                                                <p style={{ color: "#2a7fb6" }}>Borrar todo</p>
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                            placement='bottomLeft'
                        >
                            <Notifications style={{ fontSize: 35, color: "#204387", margin: "0 5px" }} />
                        </Dropdown>
                    </Badge>
                    <center className="ml-3">
                        {user &&
                            <Dropdown
                                overlay={
                                    <>
                                        <div className="reminders p-5 text-center">
                                            <div style={{ position: "relative" }}>
                                                <Upload
                                                    {...propsUpload}
                                                >
                                                    <Avatar
                                                        src={`${_SERVER._PUBLIC}img/${user.image}`}
                                                        size={100}
                                                        style={{ border: "solid #ffba00 2px" }}
                                                    />
                                                    <div
                                                        className="bg-white rounded-circle d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: 25,
                                                            width: 25,
                                                            position: "absolute",
                                                            bottom: 10,
                                                            left: '60%'
                                                        }}
                                                    >
                                                        <CameraOutlined />
                                                    </div>
                                                </Upload>
                                            </div>
                                            <p className="h5" style={{ fontWeight: "normal" }}>¡Bienvenido!</p>
                                            <p className="h5">{user.full_name}</p>
                                            <span
                                                className="input-login"
                                                style={{ padding: "2px 15px", color: "white" }}
                                            >
                                                {user.profile}
                                            </span>
                                            <div
                                                className="card-session mt-3 h5"
                                                onClick={() => props.handleModalRestorePass({ ...user, modal: "restore", profile: true, restore: 1 })}
                                            >
                                                Cambiar contraseña
                                            </div>
                                            <div
                                                className="card-session h5"
                                                style={{ borderTop: 0 }}
                                                onClick={() => props.HandreModalHellp()}
                                            >
                                                ¿Necesitas ayuda?
                                            </div>
                                            <button
                                                className="btn btn-login mt-5"
                                                onClick={() => props.onCloseSession()}
                                            >
                                                Cerrar sesión
                                            </button>
                                        </div>
                                        <Footer />
                                    </>
                                }
                                placement='bottomLeft'
                            >
                                <Avatar
                                    src={`${_SERVER._PUBLIC}img/${user.image}`}
                                    size={51}
                                    style={{ border: "solid #ffba00 2px" }}
                                />
                            </Dropdown>
                        }
                    </center>
                </div>
            </nav>
        </div>
    );
}

export const IconDelete = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={85.333}
        height={85.333}
        viewBox="0 0 64 64"
        {...props}
    >
        <path d="M23.7.6c-.4.4-.7 2-.7 3.5 0 2.8-.1 2.8-7.2 3.1l-7.3.3-1.8 3.7C5.3 14.1 5.1 15.4 6 17c.6 1.1 1.7 2 2.4 2 1.7 0 2.2 3.3 3.7 24.5.7 10.2 1.6 16.8 2.5 18.3C16 63.9 16.5 64 32.5 64c16 0 16.5-.1 17.6-2.2.7-1.3 1.9-11 2.8-21.8 1.5-17.9 1.8-19.7 3.9-21.8 2.4-2.4 2.3-4.6-.4-9C55.1 7.3 54.1 7 48 7h-6.9l-.3-3.3-.3-3.2-8-.3c-4.5-.1-8.4 0-8.8.4zm25.9 23.1C49.3 26.4 48.5 35 48 43c-.6 8-1.3 15.1-1.5 15.7-.5 1.5-27 1.9-27.9.4-.5-.8-3.6-33.8-3.6-38.3 0-1.7 1.4-1.8 17.6-1.8h17.6l-.6 4.7z" />
        <path d="M19.5 21c-1.3 2 .6 35.9 2.1 37.1 3.1 2.6 3.6-.1 2.8-15.8-.3-8.5-1-16.8-1.3-18.5-.7-3.3-2.4-4.7-3.6-2.8zM30.5 21.2c-.3.8-.4 9.5-.3 19.3.3 15.2.5 18 1.8 18s1.5-2.8 1.5-18.9c0-14.3-.3-19-1.2-19.3-.7-.3-1.5.1-1.8.9zM41.5 21c-.4.6-1.1 9-1.7 18.6-1 18.3-.6 21.6 2.6 18.4 1.9-2 2.8-37.1.9-37.7-.6-.2-1.4.1-1.8.7z" />
    </svg>
);

export const Carpeta = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={613.333}
        height={493.333}
        viewBox="0 0 460 370"
        {...props}
    >
        <path d="M33.6 1.1C21.9 3.9 10.7 13.7 4.2 26.8L.5 34.5.2 170c-.2 94.8.1 137.1.9 140.8.6 2.8 2.5 7.4 4.3 10.1 2.7 3.9 3.4 6.2 3.9 11.9.3 3.9 1.5 9.1 2.6 11.6 1.8 4.2 9.9 14.1 10.8 13.2.2-.2 2.6-59.6 5.3-132 3.6-97.1 5.2-132.4 6.2-134.4.7-1.5 2.9-3.5 4.8-4.5 3.3-1.6 13.5-1.7 179.2-1.5l175.6.3 3.1 2.2c1.7 1.3 4.4 2.3 6.3 2.3l3.3-.1-5-4.6c-2.7-2.5-8.8-6.5-13.5-8.7l-8.5-4.1-90.5-.8-90.5-.9-6.5-6.6-6.5-6.7 97.5-.3c66.9-.2 98.7 0 101.2.8 2 .6 4.6 2.2 5.7 3.6 1.6 2.1 2.7 2.5 5.8 2.2l3.8-.3-5.8-5.7c-6-6.1-16.5-11.8-24.7-13.7-2.9-.7-35.8-1.1-92-1.2l-87.5-.1-19.9-21.4L149.8 0 93.6.1c-30.8.1-57.8.5-60 1z" />
        <path d="M421 117.7c0 4.3-.3 10-.6 12.8l-.6 5H119.5l-6 2.9c-10.8 5.3-19.7 16.6-24 30.6-.9 3-9.4 48.9-18.8 102l-17.2 96.5-7 .5c-21.7 1.7 14.9 2 176.5 1.8l178.5-.3 6.6-3.2c8-4 14.6-9.6 18.9-16.3 3.1-4.8 3.8-8.2 18.1-85.8 8.2-44.4 14.9-82.8 14.9-85.3 0-5.9-2.4-14-6.1-20.6-1.6-3-2.9-6.8-2.9-8.7 0-8.5-7.5-24.2-14.6-30.7-3.5-3.2-10.1-7.3-13.1-8.3-2.3-.7-2.3-.6-2.3 7.1z" />
    </svg>
);