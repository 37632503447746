import { _USER } from "../reducers/constants";
import { _GET, _POST, _KEYS } from "../services";
// import { getFoldersByUserId } from "./folder.actions";
import { getAllPermissionsByUserId } from "./permission.actions";
import { getRemindersByUser } from "./reminder.actions";
import { getPendingsByUserId } from "./util.actions";

const _PATH = "user.php?";
function request(loading_login = false) { return { type: _USER.REQUEST, loading_login }; }
function failure(err) { return { type: _USER.FAILURE, err }; }
function success(types, message) { return { type: _USER.SUCCESS, types, message }; }
function _login(session, login) { return { type: _USER.LOGIN, session, login }; }

export function validSession() {
    return dispatch => {
        dispatch(request(true));
        const res = window.sessionStorage.getItem(_KEYS._SESSION);
        const login = (res && res !== '') ? true : false;
        if (login) {
            const session = JSON.parse(res);
            dispatch(geUsertById(session, true));
            // dispatch(getFoldersByUserId(session));
            dispatch(getAllPermissionsByUserId(session, true));
            dispatch(getRemindersByUser(session));
            dispatch(getPendingsByUserId(session));
        } else {
            dispatch(_login(undefined, login));
        }
    }
}

export function login(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "login=true", data).then(res => {
            if (res.err) {
                dispatch(success('warning', res.mss));
            } else {
                window.sessionStorage.setItem(_KEYS._TOKEN, res.session.key);
                window.sessionStorage.setItem(_KEYS._SESSION, JSON.stringify(res.session));
                dispatch(validSession());
            }
        }).catch(err => dispatch(failure(err)));
    }
}

export function getAllUsers() {
    return dispatch => {
        dispatch(request());
        _GET(_PATH + "get_all=true").then(res => {
            dispatch({ type: _USER.GET_ALL_USERS, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function geUsertById(data, valid_session = false) {
    return dispatch => {
        _POST(_PATH + "get_by_id=true", data).then(res => {
            if (valid_session) {
                if (res.user.state === "0") {
                    dispatch(logout());
                } else {
                    const session = {
                        id_user: res.user.id_user,
                        state: res.user.state,
                        id_profile: res.user.id_profile
                    };
                    window.sessionStorage.setItem(_KEYS._SESSION, JSON.stringify(session));
                    dispatch(_login(session, true));
                }
            }
            dispatch({ type: _USER.GET_USER_BY_ID, res });
        }).catch(err => dispatch(failure(err)));
    }
}

export function logout() {
    return dispatch => {
        window.sessionStorage.removeItem(_KEYS._SESSION);
        window.sessionStorage.removeItem(_KEYS._TOKEN);
        dispatch(validSession());
    }
}

export function addOrEditUser(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "add_or_edit=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
            if (!res.err && data.change) {
                dispatch(geUsertById({ id_user: data.id_user }, true));
            } else if (!res.err) {
                dispatch(getAllUsers());
                if (data.id_user && data.id_user === data._id_user) {
                    dispatch(geUsertById(data, true));
                }
            }
        }).catch(err => dispatch(failure(err)));
    }
}

export function setRestorePass(data) {
    return dispatch => {
        dispatch(request());
        _POST(_PATH + "set_restore_pass=true", data).then(res => {
            const types = res.err ? "warning" : "success";
            dispatch(success(types, res.mss));
        }).catch(err => dispatch(failure(err)));
    }
}