import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Route, Switch, HashRouter, Link } from 'react-router-dom';
import { Menu, Button } from "antd";
import { SettingOutlined, WarningOutlined } from '@ant-design/icons';
import { FileCopy, FolderOutlined, Group, AccountBoxOutlined, SearchOutlined } from '@material-ui/icons';
import Rodal from "rodal";

import { getFilesAndFoldersById } from '../actions/folder.actions';
import { addOrEditUser, getAllUsers, logout, validSession } from '../actions/user.actions';
import { getSearchFilesAndFolders } from '../actions/util.actions';
import { addOrEditReminder, getAllReminderTimes } from '../actions/reminder.actions';
import { getAllDatasheet, getDatasheetById } from '../actions/datasheet.actions';
import { Loading, View, NavDocs, Modal } from "../components";
import { getScreenMode } from '../helpers';
import { Login, Pending, Document, Share, MProfile, MUser, MDatasheet, MDocument, MRecord } from './index';
import { _SERVER } from '../services';

let time, _time = null;
class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            screen_mode: false,
            item: null,
            current: "0",
            search_text: "",
            focus: "pendientes",
            modal_confirm: false
        }
        this.linkRef = React.createRef();
        this.linkRefShare = React.createRef();
    }

    componentDidMount() {
        this.props.dispatch(validSession());
        this.setState({ screen_mode: getScreenMode() });
        window.addEventListener('resize', () => this.setState({ screen_mode: getScreenMode() }));
    }

    render() {
        const { user, loading_login, login, permission_user, _reminders_user, search_results, loading_util } = this.props;
        const { screen_mode, current, search_text, item, focus, modal_confirm } = this.state;
        return (
            <div onMouseMove={this.handleIdleSession}>
                {modal_confirm && this.handleModalConfirm()}
                <Modal
                    item={item}
                    onClose={() => this.setState({ item: null })}
                    screen_mode={screen_mode}
                />
                {loading_login ?
                    <div className="row h-100 justify-content-center align-items-center">
                        <Loading />
                    </div> :
                    login ?
                        <HashRouter>
                            <Link
                                to="/carpetas"
                                ref={this.linkRef}
                            />
                            <Link
                                to="/archivos-compartidos"
                                ref={this.linkRefShare}
                            />
                            <div className="container-home">
                                <View.NavBar
                                    user={user}
                                    screen_mode={screen_mode}
                                    reminders_user={_reminders_user}
                                    search_text={search_text}
                                    search_results={search_results}
                                    loading_util={loading_util}
                                    onCloseSession={() => this.props.dispatch(logout())}
                                    handleModalRestorePass={(item) => this.setState({ item })}
                                    handleRequest={(file) =>
                                        this.props.dispatch(addOrEditUser({
                                            ...user,
                                            _id_user: user.id_user,
                                            change: true,
                                            _image: file,
                                        }))}
                                    handleReminder={(item) => {
                                        if (item.delete) {
                                            this.props.dispatch(addOrEditReminder({
                                                ...item,
                                                visible: 0,
                                                id_user: user.id_user,
                                                delete: item.id_reminder > 0 ? 0 : 1
                                            }))
                                        } else if (item.id_share_file) {
                                            this.handleNavClick(false);
                                            this.setState({ focus: "share" });
                                        } else {
                                            this.props.dispatch(getFilesAndFoldersById({
                                                id_folder: item.id_folder,
                                                id_user: user.id_user
                                            }));
                                            this.handleNavClick(true);
                                            this.setState({ focus: parseInt(item.id_folder) });
                                        }
                                    }}
                                    onChange={(env) => {
                                        const { name, value } = env.target;
                                        if (name === "search_text" && value !== "" && value !== null) {
                                            this.props.dispatch(getSearchFilesAndFolders({
                                                name: value
                                            }));
                                        }
                                        this.setState({ [name]: value });
                                    }}
                                    onSearch={(result) => {
                                        this.props.dispatch(getFilesAndFoldersById({
                                            ...result,
                                            id_user: user.id_user
                                        }));
                                        this.setState({ search_text: "", focus: parseInt(result.id_folder) });
                                        this.handleNavClick();
                                    }}
                                    HandreModalHellp={() => this.setState({
                                        item: {
                                            modal: "view_file",
                                            path: "Manual de usuarios.pdf",
                                            type: "pdf",
                                            url: _SERVER._PUBLIC + "file/Manual de usuarios.pdf",
                                            download: true
                                        }
                                    })}
                                />
                                <div className="row" style={{ height: "80%" }}>
                                    <div className="col-2 m-0 p-0">
                                        <NavDocs
                                            screen_mode={screen_mode}
                                            handleOnClick={item => this.handleModal(item)}
                                            handleNavClick={this.handleNavClick}
                                            focus={focus}
                                            handleOnChange={focus => this.setState({ focus })}
                                        />
                                        <div className="footer-sidebar">
                                            <Menu
                                                mode="inline"
                                                selectedKeys={[current]}
                                                onClick={(e) => this.setState({ current: e.key })}
                                                inlineCollapsed={screen_mode}
                                            >
                                                {(permission_user &&
                                                    (permission_user["2"] || permission_user["3"] ||
                                                        permission_user["4"] || permission_user["5"] ||
                                                        permission_user["6"])) &&
                                                    <Menu.SubMenu
                                                        key="3"
                                                        title={<span className="btn-round">Mantenimientos</span>}
                                                        icon={<SettingOutlined style={{ fontSize: "1.2rem" }} />}
                                                        className="m-0"
                                                    >
                                                        {(permission_user && permission_user["2"]) &&
                                                            <Menu.Item
                                                                key="3.1"
                                                                className="m-0"
                                                                icon={<FolderOutlined style={{ fontSize: 25 }} />}
                                                            >
                                                                <Link
                                                                    to="/mantenimiento/documentos"
                                                                    className="btn-round"
                                                                >
                                                                    <span>Documentos</span>
                                                                </Link>
                                                            </Menu.Item>
                                                        }
                                                        {(permission_user && permission_user["5"]) &&
                                                            <Menu.Item key="3.2" className="m-0">
                                                                <Link
                                                                    to="/mantenimiento/fichas"
                                                                    className="btn-round"
                                                                >
                                                                    <FileCopy />
                                                                    <span className="title">Fichas</span>
                                                                </Link>
                                                            </Menu.Item>
                                                        }
                                                        {(permission_user && permission_user["3"]) &&
                                                            <Menu.Item key="3.3" className="m-0">
                                                                <Link
                                                                    to="/mantenimiento/usuarios"
                                                                    className="btn-round"
                                                                >
                                                                    <Group />
                                                                    <span className="title">Usuarios</span>
                                                                </Link>
                                                            </Menu.Item>
                                                        }
                                                        {(permission_user && permission_user["4"]) &&
                                                            <Menu.Item key="3.4" className="m-0">
                                                                <Link
                                                                    to="/mantenimiento/perfiles"
                                                                    className="btn-round"
                                                                >
                                                                    <AccountBoxOutlined />
                                                                    <span className="title">Perfiles</span>
                                                                </Link>
                                                            </Menu.Item>
                                                        }
                                                        {(permission_user && permission_user["6"]) &&
                                                            <Menu.Item key="3.5" className="m-0">
                                                                <Link
                                                                    to="/mantenimiento/records"
                                                                    className="btn-round"
                                                                >
                                                                    <SearchOutlined />
                                                                    <span className="title">Registros</span>
                                                                </Link>
                                                            </Menu.Item>
                                                        }
                                                    </Menu.SubMenu>
                                                }
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="col-10">
                                        <Switch>
                                            <Route
                                                exact
                                                path="/"
                                                render={() =>
                                                    <Pending
                                                        handleOnClick={item => this.handleModal(item)}
                                                        handleNavClick={(val) => this.handleNavClick(val)}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/carpetas"
                                                render={() =>
                                                    <Document
                                                        handleOnClick={item => this.handleModal(item)}
                                                        screen_mode={screen_mode}
                                                    />
                                                }
                                            />
                                            <Route
                                                path="/archivos-compartidos"
                                                render={() =>
                                                    <Share
                                                        handleOnClick={item => this.handleModal(item)}
                                                    />
                                                }
                                            />
                                            {(permission_user && permission_user["2"]) &&
                                                <Route path="/mantenimiento/documentos" component={MDocument} />
                                            }
                                            {(permission_user && permission_user["4"]) &&
                                                <Route path="/mantenimiento/perfiles"
                                                    render={() =>
                                                        <MProfile screen_mode={screen_mode} />
                                                    }
                                                />
                                            }
                                            {(permission_user && permission_user["3"]) &&
                                                <Route
                                                    path="/mantenimiento/usuarios"
                                                    render={() =>
                                                        <MUser screen_mode={screen_mode}/>
                                                    }
                                                />
                                            }
                                            {(permission_user && permission_user["5"]) &&
                                                <Route path="/mantenimiento/fichas" component={MDatasheet} />
                                            }
                                            {(permission_user && permission_user["6"]) &&
                                                <Route path="/mantenimiento/records" component={MRecord} />
                                            }
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </HashRouter> :
                        <Login />
                }
            </div>
        )
    }

    handleModalConfirm = () => {
        const { modal_confirm } = this.state;
        return (
            <Rodal
                animation="door"
                duration={500}
                visible={modal_confirm}
                closeMaskOnClick
                showCloseButton={false}
                customStyles={{ borderRadius: 10 }}
                width={400}
                height={160}
            >
                <p className="h5"><WarningOutlined style={{ color: 'orange' }} /> Tiempo de sesión agotado por inactividad</p>
                <br />
                <p>¿Desea continuar con la sesión actual?</p>
                <div className="d-flex w-100 justify-content-end">
                    <Button
                        type="text"
                        danger
                        onClick={() => this.setState({ modal_confirm: false }, () => this.props.dispatch(logout()))}
                        className="mr-1"
                    >
                        No
                    </Button>
                    <Button
                        type="text"
                        onClick={() => this.setState({ modal_confirm: false }, () => clearTimeout(_time))}
                    >
                        Sí
                    </Button>
                </div>
            </Rodal>
        )
    }

    handleIdleSession = () => {
        const { login } = this.props;
        if (login && process.env.NODE_ENV !== 'development') {
            clearTimeout(time);
            time = setTimeout(() => {
                this.setState({ modal_confirm: true }, () => {
                    _time = setTimeout(() => {
                        this.setState({ modal_confirm: false }, () => this.props.dispatch(logout()));
                    }, 60000);
                })
            }, 600000);
        }
    }

    handleNavClick = (document = true) => {
        if (this.linkRef && document) {
            this.linkRef.current.click();
        } else if (this.linkRefShare && !document) {
            this.linkRefShare.current.click();
        }
    }

    handleModal = (item) => {
        if (item.modal === "file" && item.id_datasheet !== "0" && item.id_datasheet !== "") {
            this.props.dispatch(getDatasheetById(item));
        } else if (item.modal === "define") {
            this.props.dispatch(getAllDatasheet());
        } else if (item.modal === "share") {
            this.props.dispatch(getAllUsers());
        } else if (item.modal === "reminder") {
            this.props.dispatch(getAllReminderTimes());
        }
        this.setState({ item });
    }
}

function mapStateToProps(state) {
    const { _user, _reminder, _permission, _util } = state;
    const { user, loading_login, login } = _user;
    const { _reminders_user } = _reminder;
    const { permission_user, loading_perm, modules, permissions, permissions_folder } = _permission;
    const { search_results, loading_util } = _util;
    return {
        user, loading_login, login,
        _reminders_user,
        permission_user, loading_perm, modules, permissions, permissions_folder,
        search_results, loading_util
    }
}

export default connect(mapStateToProps)(Navigation);